<template>
    <div class="yume-login">
        <div class="login-container">
            <h1>Entrar</h1>
            <div class="form-container">
                <form @submit.prevent="login()" id="login-form">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" id="email" name="email" maxlength="100" required>
                    </div>
                    <div class="form-group">
                        <label for="password">Senha</label>
                        <input type="password" id="password" name="password" maxlength="100" required>
                    </div>
                    <button type="submit" class="btn btn-primary btn-100" id="login-button">Entrar</button>
                    <h3 class="response">{{ response }}</h3>
                    <h3>Ainda não é membro? <router-link to="/register">Cadastre-se</router-link></h3>
                </form>
                <div class="phrase">
                    <h2>Conheça Yuume: Onde pensamentos são protegidos e conexões são feitas com acolhimento.</h2>
                </div>
            </div>
        </div>
        <footer>
            <p class="copy">© {{ year }} Yuume, todos os direitos reservados</p>
        </footer>
    </div>
</template>
<script>
import $ from 'jquery';
import api from '../configs/api.js';
import { globalMethods } from '../js/globalMethods';

export default {
    name: "indexPage",
    mixins: [globalMethods],
    data() {
        return {
            year: null
        }
    },
    methods: {
        login: function () {
            let self = this;

            let loginButton = $("#login-button");
            loginButton.attr("disabled", "disabled").addClass("btn-loading");

            let data = $("#login-form").serializeArray().reduce(function (obj, item) { // Pega todos os dados do formulário e coloca em um objeto.
                obj[item.name] = item.value;
                return obj;
            }, {});

            api.post("/users/login", data).then((res) => {
                self.setResponse(res.data.message, "success");
                self.setJwtInLocalStorage(res.data.returnObj.jwtToken);
                self.$router.push("/dashboard");
            }).catch((error) => {
                self.setResponse(error.response.data, "error");
            }).then(() => {
                loginButton.removeAttr("disabled").removeClass("btn-loading");
            })
        }
    },
    mounted: function () {
        let now = new Date();
        this.year = now.getFullYear();

        this.checkIfUserIsAuthenticated();
    }
}
</script>
<style scoped>
.yume-login {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.login-container {
    background: var(--pink-mid);
    border: 3px solid var(--purple);
    width: 95vw;
    max-width: 1000px;
    min-width: 200px;
    height: fit-content;
    padding: var(--space-6);
    border-radius: var(--radius-sm);
    display: flex;
    flex-direction: column;
    margin-top: var(--space-10);
}

.phrase, form {
    width: calc(50% - var(--space-6));
    margin: var(--space-6) 0;
}

@media (max-width: 768px) {
    .form-container {
        flex-direction: column;
    }

    .phrase, form {
        width: 100%;
    }
}
</style>