import $ from 'jquery';
import moment from 'moment';
import api from '../configs/api.js';

export const globalMethods = {
    methods: {
        resetResponse: function () {
            let response = $(".response");

            this.response = "";
            response.removeClass("error").removeClass("success");
        },
        setResponse: function (message, objClass) {
            this.resetResponse();

            let response = $(".response");

            response.addClass(objClass);

            if (objClass == "error") {
                $("#modal-submit-button").removeAttr("disabled").removeClass("btn-loading");
                $("#modal-save-submit-button").removeAttr("disabled").removeClass("btn-loading");
            }

            this.response = message;
        },
        setJwtInLocalStorage: function (token) {
            localStorage.setItem("yuume_jwt", token);
            this.checkAndSetJwt();
        },
        getJwtInLocalStorage: function () {
            return localStorage.getItem("yuume_jwt");
        },
        removeJwtFromLocalStorage: function () {
            localStorage.removeItem("yuume_jwt");
        },
        checkAndSetJwt: function() {
            let interval = setInterval(() => {
                let jwt = this.getJwtInLocalStorage();
                if (jwt != null) {
                    api.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
                    this.$root.jwtLoaded = true;
                    clearInterval(interval);
                }
            }, 100)
        },
        logoutUser: function () {
            let self = this;

            if (self.$root.socket != undefined) {
                this.$root.socket.send(JSON.stringify({ type: 'offline', friends: this.$root.user.friend_ids }));

                this.$root.socket.close();
            }

            self.removeJwtFromLocalStorage();
            self.$router.push("/login");
        },
        requireUser: function () {
            return new Promise((resolve) => {
                let self = this;

                api.get("/users/return_user").then((response) => {
                    self.$root.user = response.data.returnObj;
                    resolve();
                }).catch(() => {
                    location.reload();
                })
            })
        },
        checkIfUserIsAuthenticated: function () {
            return new Promise((resolve) => {
                let self = this;
                let pathName = window.location.href;
                let jwt = "Bearer " + self.getJwtInLocalStorage();
                
                if (jwt == "Bearer null") {
                    if (pathName.indexOf("/dashboard") != -1) {
                        self.$router.push("/login");
                        return;
                    }
                } else {
                    let data = {
                        token: jwt
                    }

                    api.post("/users/check_jwt", data) // Se ja estiver logado no sistema e acessar a página de login, é checkado a valia do token JWT e então redirecionado para a index.
                    .then(function (res) { 
                        self.setJwtInLocalStorage(res.data.returnObj.newToken); // Setando o novo jwt que foi resetado

                        if (pathName.indexOf("/login") != -1) { // Se o usuário estiver logado e entrar em login, o mesmo é logado novamente e direcionado para a index.
                            let loginForm = $("#login-form");
                            loginForm.find("input").attr("disabled", "disabled");
                            loginForm.find("button").attr("disabled", "disabled").addClass("btn-loading");

                            setTimeout(() => {
                                self.$router.push("/dashboard");
                            }, 1000);
                        }
                        resolve();
                    })
                    .catch(function () { // Caso contrário ele é deslogado e enviado para login.
                        self.logoutUser();
                        return;
                    })
                    .then(function () { // Chamada recursiva da função se o usuario estiver em dashboard
                        if (pathName.indexOf("/dashboard") != -1) {
                            setTimeout(self.checkIfUserIsAuthenticated, 10 * 1000);
                        }
                    })
                }
            })
        },
        formatDate: function (dateString) {
            const dataMoment = moment(dateString, "YYYY-MM-DD hh:mm:ss");
            const diffDays = moment().diff(dataMoment, 'days');

            if (diffDays <= 0) {
                return dataMoment.fromNow();
            } else if (diffDays <= 7) {
                return dataMoment.format("DD/MM/YYYY");
            } else {
                return dataMoment.fromNow();
            }
        },
        countRowsExtended: function (elementId, event, keyup = false, callback = () => {}, index = null) {
            let input = $("#" + elementId);
            let length = input.val().length;
            let keycode = event.keyCode;

            if (keycode == 13) {
                if (keyup) {
                    if(event.shiftKey){
                        $(input).val($(input).val() + '\n'); // shift + enter = Quebra linha
                        $(input).scrollTop(10000000);
                        return;
                    }
                    callback(index);
                }
                event.preventDefault();
            }
            
            let charsPerLine = 68; // Número médio de caracteres por linha
            let numLines = Math.ceil(length / charsPerLine); // Arredonda para cima para garantir que todas as linhas sejam contabilizadas
            
            if (numLines > 5) {
                input.attr("rows", numLines);
            } else {
                input.attr("rows", 5);
            }
        },
        countRows: function (event, keyup = false, callback = () => {}, callbackParam = null) {
            let input = $("#" + event.target.id);
            let length = input.val().length;
            let keycode = event.keyCode;

            if (keycode == 13) {
                if (keyup) {
                    if(event.shiftKey){
                        $(input).val($(input).val() + '\n'); // shift + enter = Quebra linha
                        $(input).scrollTop(10000000);
                        return;
                    }
                    input.attr("rows", 1);
                    callback(callbackParam);
                }
                event.preventDefault();
            }
            
            if (length <= 30) {
                input.attr("rows", 1);
            } else if (length > 30 && length <= 60) {
                input.attr("rows", 2);
            } else if (length > 60 && length <= 90) {
                input.attr("rows", 3);
            } else {
                input.attr("rows", 4);
            }
        }
    },
    data() {
        return {
            response: ""
        }
    },
    mounted: function () {
        moment.locale('pt-br');
    }
}