<template>
    <div class="notifications">
        <div class="notifications-button" v-on:click="toggleNotificationsContainer()">
            <span class="material-icons">notifications</span>
            <div class="notifications-number" v-if="notifications.length > 0">{{ notifications.length }}</div>
        </div>
        <div class="notifications-container" v-if="notifications.length > 0">
            <div class="notification" v-for="(item, index) in notifications.objects" :key="index" v-on:click="handleClickNotification(item)">
                <div class="notification-informations">
                    <img :src="item.user_profile_photo" class="avatar-pp">
                    <h3><strong>{{ item.user_nickname }}</strong><span v-html="returnNotificationText(item)" class="notification-text"></span></h3>
                </div>
                <div class="friendship-buttons" v-if="item.type == 'frienship_request'">
                    <button v-on:click="acceptFriendshipRequest(item.user_id, $event)">Aceitar</button>
                    <button v-on:click="rejectFriendshipRequest(item.user_id, $event)">Rejeitar</button>
                </div>
            </div>
        </div>
        <div class="notifications-wrapper" v-on:click="toggleNotificationsContainer()"></div>
    </div>
</template>
<script>
import $ from 'jquery';
import { globalMethods } from '../js/globalMethods';
import api from '../configs/api.js';

export default {
    name: "notificationsComponent",
    mixins: [globalMethods],
    data() {
        return {
            notifications: {
                length: 0,
                objects: [
                    /*{
                        id: 0,
                        type: "comment",
                        user_id: 0,
                        user_nickname: "Ana Clara",
                        user_profile_photo: "https://pics.craiyon.com/2023-10-23/903eec12f88642079733f99cf3e1eb17.webp",
                        id_post: 0,
                        post_first_words: "Nunca pense em...",
                        timestamp: "02-15-2024 20:59:15",
                        message: "Foto fofa"
                    },
                    {
                        id: 1,
                        type: "like",
                        user_id: 0,
                        user_nickname: "Ana Clara",
                        user_profile_photo: "https://pics.craiyon.com/2023-10-23/903eec12f88642079733f99cf3e1eb17.webp",
                        id_post: 0,
                        post_first_words: "Nunca pense em...",
                        timestamp: "02-15-2024 20:59:15",
                        message: ""
                    },
                    {
                        id: 2,
                        type: "frienship_request",
                        user_id: 0,
                        user_nickname: "Ana Clara",
                        user_profile_photo: "https://pics.craiyon.com/2023-10-23/903eec12f88642079733f99cf3e1eb17.webp",
                        id_post: null,
                        post_first_words: "",
                        id_comment: null,
                        timestamp: "02-15-2024 20:59:15",
                        message: ""
                    },
                    {
                        id: 3,
                        type: "sharing",
                        user_id: 0,
                        user_nickname: "Ana Clara",
                        user_profile_photo: "https://pics.craiyon.com/2023-10-23/903eec12f88642079733f99cf3e1eb17.webp",
                        id_post: 0,
                        post_first_words: "Nunca pense em...",
                        id_comment: null,
                        timestamp: "02-15-2024 20:59:15",
                        message: ""
                    }*/
                ]
            }
        }
    },
    methods: {
        acceptFriendshipRequest: function (user_id, event) {
            let element = $(event.target).parent().parent();

            element.find(".notification-text").html(" Vocês são amigos agora!");
            element.find(".friendship-buttons").remove();

            let data = {
                requesting_user: user_id
            }

            let self = this;

            api.post("/friends/accept_request", data).then(() => {
                self.requireUser().then(() => {
                    self.$root.socket.send(JSON.stringify({ type: "reload_system" }));
                })
            })
        },
        rejectFriendshipRequest: function (user_id, event) {
            let element = $(event.target).parent().parent();
            element.remove();

            this.toggleNotificationsContainer();

            let self = this;

            let data = {
                requesting_user: user_id
            }

            api.post("/friends/reject_request", data).then(() => {
                self.returnNotifications();
            });
        },
        returnNotificationText: function (item) {
            let text = "";

            switch (item.type) {
                case "sharing":
                    text = " " + item.more_text + " sua publicação <strong>" + item.post_first_words + "</strong>";
                    break;
                case "frienship_request":
                    text = " enviou um pedido de amizade";
                    break;
                case "like":
                    text = " " + item.more_text + " sua publicação <strong>" + item.post_first_words + "</strong>";
                    break;
                case "comment":
                    text = " " + item.more_text + " em sua publicação <strong>" + item.post_first_words + "</strong>: \"" + item.message + "\"";
                    break;
            }

            return text;
        },
        handleClickNotification: function (item) {
            if (item.type == "frienship_request") return;
            if (window.location.pathname.indexOf("/dashboard/post/" + item.id_post) != -1) return;
            
            let commentParam = "";

            if (item.id_comment != null) {
                commentParam = "?comment=" + item.id_comment;
            }

            this.$router.push("/dashboard/post/" + item.id_post + commentParam);
        },
        toggleNotificationsContainer: function () {
            if (this.notifications.length == 0) return;

            let container = $(".notifications-container");
            let wrapper = $(".notifications-wrapper");

            if (container.is(":visible")) {
                container.hide();
                wrapper.hide();
            } else {
                let self = this;

                this.returnNotifications().then(() => {
                    self.$root.socket.send(JSON.stringify({ type: "view_notifications" }));
                });

                container.show();
                wrapper.show();
            }
        }, 
        returnNotifications: function () {
            return new Promise((resolve) => {
                let self = this;

                api.get("/notifications").then((response) => {
                    self.notifications = response.data.returnObj;
                    resolve();
                })
            })
        },
        wsNotifications: function () {
            let self = this;

            self.$root.socket.addEventListener("message", function (event) {
                let message = JSON.parse(event.data);

                if (message.type == "reload_notifications") {
                    self.notifications.length++;
                }
            });
        }
    },
    mounted: function () {
        this.wsNotifications();
        this.returnNotifications();
    }
}
</script>
<style scoped>
.notifications {
    position: relative;
    cursor: pointer;
}

.notifications-button span {
    font-size: var(--fontsize-lg);
}
.notifications-number {
    width: 20px;
    height: 20px;
    border-radius: var(--radius-full);
    background: var(--red);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    position: absolute;
    bottom: 0;
    left: 0;
}

.notifications-container {
    position: absolute;
    background: var(--pink-mid);
    padding: var(--space-3);
    border-radius: var(--radius-sm);
    border: 3px solid var(--purple);
    top: 90%;
    display: none;
    z-index: 5;
    right: 0;
    width: 300px;
}

.notification-informations {
    display: flex;
}

.notification {
    cursor: pointer;
    padding: var(--space-3);
    border-radius: var(--radius-sm);
}

    .notification:hover {
        background: var(--pink-low);
    }

.notifications-wrapper {
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4;
    display: none;
}

.friendship-buttons {
    display: flex;
    align-items: center;
    padding-left: 3.5rem;
    margin: var(--space-3) 0;
}

    .friendship-buttons button {
        padding: var(--space-3);
        border-radius: var(--radius-sm);
        cursor: pointer;
        background: var(--pink);
        border: 2px solid var(--purple);
        margin-right: var(--space-3);
        font-family: Yume;
    }

        .friendship-buttons button:hover {
            background: var(--pink-low);
        }

    .friendship-buttons button:first-child {
        background: var(--purple);
        color: var(--white);
    }

        .friendship-buttons button:first-child:hover {
            background: var(--purple-high);
        }
</style>