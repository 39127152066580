<template>
  <div id="app">
    <router-view path="$router.key" />
  </div>
</template>

<script>
import './style/global.css';

export default {
  name: 'App'
}
</script>

<style>
#app {
  background-image: linear-gradient(to top right, var(--pink), var(--pink-high));
  min-height: 100vh;
}
</style>
