<template>
    <div class="my-diary">
        <h1>Meu diário</h1>
        <div class="write">
            <div class="page-header">
                <div class="date">
                    <h3>{{ day }}</h3>
                    <h3 class="month">{{ month }}</h3>
                    <h3>{{ year }}</h3>
                </div>
            </div>
            <div class="write-container">
                <span class="material-icons">edit</span>
                <textarea name="diary" id="diary" v-model="diaryText" placeholder="O que aconteceu no seu dia hoje?"></textarea>
                <p class="response error">{{ response }}</p>
                <div class="write-footer">
                    <button type="button" class="btn btn-primary" id="send-diary" v-on:click="sendDiary()">Guardar</button>
                    <p class="counter" :class="diaryText.length > 5000 ? 'text-out' : ''">{{ diaryText.length + "/5000" }}</p>
                </div>
            </div>
        </div>
        <div class="diary-pages">
            <div class="page" v-for="(page, index) in diaryPages" :key="index" :id="'diary-page-' + page.id">
                <div class="page-header">
                    <div class="date">
                        <h3>{{ findDay(page.create_date) }}</h3>
                        <h3 class="month">{{ findMonth(page.create_date) }}</h3>
                        <h3>{{ findYear(page.create_date) }}</h3>
                    </div>
                    <div class="options">
                        <span class="material-icons" v-on:click="toogleMore(page.id)">more_vert</span>
                        <div class="more-container">
                            <ul>
                                <li v-on:click="deleteDiaryPage(page.id)">Jogar fora</li>
                            </ul>
                        </div>
                    </div>
                    <div class="more-wrapper" v-on:click="toogleMore(page.id)"></div>
                </div>
                <div class="text">
                    <h3 v-html="page.diary_text"></h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { marked } from 'marked';
import moment from 'moment';
import $ from 'jquery';
import api from '../configs/api.js';
import { globalMethods } from '../js/globalMethods';

export default {
    name: "myDiary",
    mixins: [globalMethods],
    data() {
        return {
            day: moment().date(),
            month: moment().format('MMMM'),
            year: moment().year(),
            diaryText: "",
            diaryPages: []
        }
    },
    methods: {
        findDay: function (date) {
            let momentDate = moment(date);

            return momentDate.date();
        },
        findMonth: function (date) {
            let momentDate = moment(date);

            return momentDate.format("MMMM");
        },
        findYear: function (date) {
            let momentDate = moment(date);

            return momentDate.year();
        },
        toogleMore: function (page_id) {
            let container = $("#diary-page-" + page_id + " .more-container");
            let wrapper = $("#diary-page-" + page_id + " .more-wrapper");

            if (container.is(":visible")) {
                container.hide();
                wrapper.hide();
            } else {
                container.show();
                wrapper.show();
            }
        },
        sendDiary: function () {
            if (this.diaryText.trim() == "") return;

            let page = {
                date: moment().format("YYYY-MM-DD HH:mm:ss"),
                diary_text: this.diaryText
            }

            let self = this;

            let submitButton = $("#send-diary");
            submitButton.attr("disabled", "disabled").addClass("btn-loading");

            api.post("/users/diary", page).then((response) => {
                page["id"] = response.data.returnObj;
                self.diaryPages.unshift(page);

                setTimeout(() => {
                    self.refreshDiaryTexts();
                }, 1000)
            }).catch((error) => {
                self.setResponse(error.data.message, "error");
            }).then(() => {
                self.diaryText = "";
                submitButton.removeAttr("disabled").removeClass("btn-loading");
            })
        },
        returnDiaryPages: function () {
            let self = this;

            api.get("/users/diary").then((response) => {
                self.diaryPages = response.data.returnObj;

                self.refreshDiaryTexts();
            })
        },
        refreshDiaryTexts: function () {
            this.diaryPages.forEach(page => {
                page.diary_text = marked(page.diary_text, { breaks: true });
            });
        },
        deleteDiaryPage: function (page_id) {
            api.delete("/users/diary/" + page_id + "/delete_page").then(() => {
                $("#diary-page-" + page_id).remove();
            })
        }
    },
    mounted: function () {
        this.returnDiaryPages();
    }
}
</script>
<style scoped>
.write {
    width: 100%;
    background: var(--purple-high);
    border-radius: var(--radius-sm);
    padding: var(--space-5);
    position: relative;
}

.write-container {
    margin-top: var(--space-5);
    position: relative;
    display: flex;
    flex-direction: column;
}

    .write-container span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 7em;
        z-index: 1;
        opacity: 0.1;
    }

.write-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}

    .write-footer button {
        margin: 0;
    }

textarea {
    border: none;
    background: none;
    width: 100%;
    height: 300px;
    margin-bottom: var(--space-5);
    resize: none;
    color: var(--white);
    position: relative;
    z-index: 2;
    padding: 0;
}

    textarea::placeholder {
        color: var(--placeholder);
    }

textarea::-webkit-scrollbar {
  width: 7px;
}

textarea::-webkit-scrollbar-track {
  background: transparent;
}

textarea::-webkit-scrollbar-thumb {
  background: var(--purple);
  border-radius: var(--radius-sm);
  cursor: pointer;
}
</style>

<style scoped>
.month {
    text-transform: capitalize;
}

.my-diary {
    width: 100vw;
    max-width: 680px;
    padding: var(--space-5);
    margin: auto;
    height: 100vh;
    color: var(--white);
    overflow-y: auto;
}

@media (max-width: 1440px) {
    .my-diary {
        height: 100%;
    }
}

.my-diary::-webkit-scrollbar {
  width: 7px;
}

.my-diary::-webkit-scrollbar-track {
  background: transparent;
}

.my-diary::-webkit-scrollbar-thumb {
  background: var(--pink);
  border-radius: var(--radius-sm);
}

.text-out {
    color: #EF5DA8;
}

.write {
    margin: var(--space-5) 0;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: var(--space-5);
    border-bottom: 1px solid var(--purple);
}

.date h3 {
    margin: var(--space-2) 0;
}

.page {
    width: 100%;
    background: var(--purple-high);
    border-radius: var(--radius-sm);
    padding: var(--space-5);
    position: relative;
    margin-bottom: var(--space-5);
}

    .page .text {
        margin-top: var(--space-5);
    }

.more-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: transparent;
    display: none;
    z-index: 3;
}

.options {
    position: relative;
    z-index: 4;
}

    .options span {
        cursor: pointer;
    }

.more-container {
    background: var(--pink);
    border-radius: var(--radius-sm);
    color: var(--purple);
    position: absolute;
    right: var(--space-8);
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    display: none;
    height: fit-content;
}

    .more-container ul {
        list-style: none;
    }

        .more-container ul li {
            cursor: pointer;
            white-space: nowrap;
            padding: var(--space-3);
        }
</style>