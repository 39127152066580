<template>
    <div class="menu">
        <ul>
            <li>
                <router-link @click.native="emit()" to="/dashboard">
                    <span class="material-icons">home</span>
                    <h2>Início</h2>
                </router-link>
            </li>
            <li>
                <router-link @click.native="emit()" to="/dashboard/friends">
                    <span class="material-icons">people</span>
                    <h2>Amigos</h2>
                </router-link>
            </li>
            <li>
                <router-link @click.native="emit()" to="/dashboard/my-diary">
                    <span class="material-icons">menu_book</span>
                    <h2>Meu diário</h2>
                </router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "menuComponent",
    methods: {
        emit: function () {
            this.$emit("select");
        }
    }
}
</script>
<style scoped>
.menu {
    background: var(--pink-mid);
    border: 3px solid var(--purple);
    border-radius: var(--radius-sm);
    padding: var(--space-3);
}

ul {
    list-style: none;
}

ul > li a {
    padding: var(--space-4);
    border-radius: var(--radius-sm);
    margin: var(--space-3) 0;
    cursor: pointer;
    color: var(--purple);
    display: flex;
    align-items: center;
    text-decoration: none !important;
    width: 100%;
    height: 100%;
}

    ul > li a:hover {
        background: var(--pink-low);
    }

    ul > li a:first-child {
        margin-top: 0 !important;
    }

    ul > li a:last-child {
        margin-bottom: 0 !important;
    }

    a h2 {
        margin-left: var(--space-3);
    }

    a h2, a span {
        text-decoration: none !important;
    }
</style>