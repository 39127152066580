<template>
    <div class="feed" v-on:scroll="handleFeedScroll($event)">
        <div class="search">
            <input type="text" name="search" id="search" v-model="searchString" @keypress="search($event)" placeholder="Procurar">
            <span class="material-icons" v-on:click="search({ key: 'Enter' })">search</span>
        </div>
        <div class="make-post" v-if="!searching">
            <div class="input-group">
                <textarea name="make_post" id="make-post" v-on:click="togglePostInput()" rows="1" placeholder="Conte o que você está sentindo..."></textarea>
                <button type="button" class="btn btn-primary" v-on:click="makePost()">Publicar</button>
            </div>
            <postUploadContent @upload="postImage = $event" @clear="postImage = ''" :clear="clearPost" />
        </div>
        <div class="make-post-wrapper" v-on:click="togglePostInput()"></div>
        <div class="post-list" v-if="!searching">
            <postComponent :post="post" v-for="(post, index) in posts" :key="index" />
        </div>
        <div class="search-users" v-if="searching">
            <div class="searching-users" v-if="searchAjax && searchUsers.length == 0">
                <h3>Procurando usuários</h3>
                <div class="loading"></div>
            </div>
            <div class="searching-users" v-if="!searchAjax && searchUsers.length == 0">
                <h3>Nenhum usuário encontrado</h3>
            </div>
            <div class="user" v-for="(user, index) in searchUsers" :key="index">
                <router-link :to="'/dashboard/users/' + user.id">
                    <div class="image-container">
                        <img :src="user.profile_photo" class="avatar-p">
                    </div>
                    <h2>{{ user.nickname }}</h2>
                </router-link>
            </div>
        </div>
        <div class="load-more" v-if="loadMore && !noMorePostsToLoad">
            <div class="loading"></div>
        </div>
    </div>
</template>
<script>
import { globalMethods } from '../js/globalMethods';
import moment from 'moment';
import postComponent from "./postComponent.vue";
import postUploadContent from "./postUploadContent.vue";
import $ from 'jquery';
import api from '../configs/api.js';

export default {
    name: "globalFeed",
    mixins: [globalMethods],
    components: {
        postComponent,
        postUploadContent
    },
    watch: {
        searchString: function () {
            if (this.searchString.trim() == "") {
                this.searching = false;
            }
        },
        clearPost: function () {
            if (this.clearPost) {
                setTimeout(() => {
                    this.clearPost = false;
                }, 1)
            }
        }
    },
    methods: {
        search: function (event) {
            if (event.key == "Enter" && this.searchString.trim().length > 2) {
                let self = this;

                self.searchAjax = true;

                self.searching = true;

                let data = {
                    searchString: this.searchString
                }

                api.post("/users/search_users", data).then((response) => {
                    self.searchUsers = response.data.returnObj;
                    self.searchAjax = false;
                }).catch((error) => {
                    console.log(error);
                })
            }
        },
        makePost: function () {
            if (this.posting) return;

            let element = $("#make-post");
            let text = element.val();
            let objectToSend = this.postImage;

            if (text.trim() == "" && this.postImage == "") {
                this.closePostInput();
                return;
            }

            this.posting = true;

            if (this.postImage == "") {
                objectToSend = {
                    post_text: text
                }
            } else {
                objectToSend.append("post_text", text);
            }

            let self = this;

            api.post("/posts/make_post", objectToSend).then((results) => {
                self.posting = false;

                self.appendPost(results.data.returnObj);
            })

            $("#make-post").val("");
            this.closePostInput();
            this.clearPost = true;
        },
        appendPost: function (post) {
            let obj = {
                id: post.id,
                post_text: post.post_text,
                post_image: post.post_image,
                user_id: this.$root.user.id,
                nickname: this.$root.user.nickname,
                profile_photo: this.$root.user.profile_photo,
                create_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                likes: 0,
                comments: 0,
                shares: 0
            }

            this.posts.unshift(obj);
        },
        togglePostInput: function () {
            let element = $("#make-post");
            let wrapper = $(".make-post-wrapper");

            if (element.css("min-height") == "200px") {
                this.closePostInput();
            } else {
                element.css("min-height", "200px");
                wrapper.show();
            }
        },
        closePostInput: function () {
            let element = $("#make-post");
            let wrapper = $(".make-post-wrapper");

            if (element.css("min-height") == "200px") {
                element.css("min-height", "40px");
                wrapper.hide();
            }
        },
        returnFeed: function () {
            let self = this;

            if (this.noMorePostsToLoad) return;

            api.get("/posts/return_feed?offset=" + this.offset + "&limite=20").then((results) => {
                if (results.data.returnObj.length == 0) {
                    self.noMorePostsToLoad = true;
                } else {
                    self.posts = self.posts.concat(results.data.returnObj);
                    self.offset += self.posts.length;
                }

                self.loadMore = false;
            })
        },
        handleFeedScroll: function (event) {
            const element = event.target;
            const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

            if (atBottom) {
                this.loadMore = true;
                this.returnFeed();
            }
        }
    },
    data() {
        return {
            searchAjax: false,
            searchString: "",
            postImage: "",
            searching: false,
            offset: 0,
            loadMore: false,
            noMorePostsToLoad: false,
            searchUsers: [],
            posts: [],
            posting: false,
            clearPost: false
        }
    },
    mounted: function () {
        this.returnFeed();
    }
}
</script>
<style scoped>
.user {
    display: flex;
    align-items: center;
    margin: var(--space-1) 0;
    padding: var(--space-3);
    border-radius: var(--radius-sm);
    cursor: pointer;
}

.feed {
    width: 100vw;
    max-width: 680px;
    padding: var(--space-5);
    margin: auto;
    height: 100vh;
    color: var(--white);
    overflow-y: auto;
    padding-bottom: calc(8vh);
}

@media (max-width: 1440px) {
    .feed {
        height: 100%;
    }
}

    .feed > div {
        width: 100%;
    }

.feed::-webkit-scrollbar {
  width: 7px;
}

.feed::-webkit-scrollbar-track {
  background: transparent;
}

.feed::-webkit-scrollbar-thumb {
  background: var(--pink);
  border-radius: var(--radius-sm);
}

.searching-users {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--space-5) 0;
}

    .searching-users h3 {
        margin-bottom: var(--space-5);
    }

.search {
    position: relative;
    margin: vaR(--space-5) 0;
}

    .search input {
        width: 100%;
    }

    .search span {
        position: absolute;
        right: .7rem;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        color: var(--purple);
        cursor: pointer;
    }

.input-group {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 4;
}

    .input-group button {
        margin: 0;
    }

    .input-group textarea {
        width: calc(100% - 80px);
        margin-right: var(--space-1);
        min-height: 40px;
        max-height: 200px;
        transition: all 0.4s;
    }

.make-post {
    margin-bottom: var(--space-5);
}

.make-post-wrapper {
    background: transparent;
    display: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}

.user a {
    display: flex;
    align-items: center;
    color: var(--white);
    text-decoration: none;
    width: 100%;
}
</style>
<style scoped>
.load-more {
    display: flex;
    justify-content: center;
    margin: var(--space-8) 0;
}
</style>