<template>
    <div class="user-page">
        <div class="user-not-found" v-if="user.id == undefined">
            <h1>Usuário não encontrado</h1>
        </div>
        <div class="main-user" v-if="user.id != undefined">
            <div class="banner" :style="'background: url(' + user.banner_photo + ');'">
                <button type="button" class="change-banner" v-on:click="requestUploadBanner()" v-if="user.id == $root.user.id">
                    <span class="material-icons">edit</span>
                </button>
                <form class="send-photo" method="post" enctype="multipart/form-data" @submit.prevent="uploadBanner(formData)">
                    <input type="file" name="banner" id="banner" style="display: none;" @change.prevent="preSendBanner($event)" title="Envie uma foto nos formatos PNG ou JPG">
                    <input type="submit" id="submit-input-banner" style="display: none;">
                </form>
            </div>
            <div class="user-container">
                <div class="user-inner">
                    <div class="image-container">
                        <img :src="user.profile_photo" class="avatar-g" v-on:click="togglePhotoSend()" :style="user.id != $root.user.id ? 'cursor: default;' : ''">
                        <div class="change-photo">
                            <ul>
                                <li v-on:click="requestUploadImage()">Alterar foto</li>
                            </ul>
                            <form class="send-photo" method="post" enctype="multipart/form-data" @submit.prevent="uploadPhoto(formData)">
                                <input type="file" name="photo" id="photo" style="display: none;" @change.prevent="preSendPhoto($event)" title="Envie uma foto nos formatos PNG ou JPG">
                                <input type="submit" id="submit-input" style="display: none;">
                            </form>
                        </div>
                        <div class="photo-wrapper" v-on:click="togglePhotoSend()"></div>
                    </div>
                    <div class="user-informations">
                        <h2>{{ user.nickname }}</h2>
                        <h3 v-if="this.user.blocked == 0 && this.user.requesting_user_has_blocked == 0">{{ user.friends }} {{ user.friends > 1 || user.friends == 0 ? "amigos" : "amigo" }}</h3>
                    </div>
                </div>
                <div class="user-button" v-if="!reload && user.id != $root.user.id || (this.user.blocked != 1 && this.user.requesting_user_has_blocked != 0)">
                    <button class="btn btn-primary" v-on:click="toggleAddButtonContainer()" v-html="returnAddButtonText()"></button>
                    <div class="button-container">
                        <ul>
                            <li v-on:click="unfriend()" v-if="!is_blocked">
                                Desfazer amizade
                            </li>
                            <li v-on:click="block()" v-if="!is_blocked">
                                Bloquear
                            </li>
                            <li v-if="is_blocked" v-on:click="unblock()">
                                Desbloquear
                            </li>
                        </ul>
                    </div>
                    <div class="button-wrapper" v-on:click="toggleAddButtonContainer()"></div>
                </div>
            </div>
            <div class="tabs">
                <ul>
                    <li v-on:click="showPosts($event)" id="show-posts">Publicações</li>
                    <li v-on:click="showFriends($event)">Amigos</li>
                </ul>
            </div>
            <div class="private-account" v-if="user.id != $root.user.id && user.visibility != 'visible'">
                <h3>Esta conta é privada</h3>
            </div>
            <div class="posts-container" v-if="showPostsVar && checkIfAccountMustBeDisplayed()">
                <div class="no-posts" v-if="posts.length == 0">
                    <h3>{{ user.nickname }} não fez nenhum post ainda</h3>
                </div>
                <postComponent :post="post" v-for="(post, index) in posts" :key="index" />
            </div>
            <div class="friends-container" v-if="showFriendsVar && checkIfAccountMustBeDisplayed()">
                <div class="no-friends" v-if="user.friends_objects.length == 0">
                    <h3>Nenhum amigo encontrado</h3>
                </div>
                <div class="user" v-for="(userItem, index) in user.friends_objects" :key="index">
                    <router-link :to="'/dashboard/users/' + userItem.id" @click.native="resetPage(userItem.id);">
                        <div class="image-container">
                            <img :src="userItem.profile_photo" class="avatar-p">
                        </div>
                        <div class="user-informations">
                            <h2>{{ userItem.nickname }}</h2>
                            <h3>{{ userItem.friends }} {{ userItem.friends > 1 || userItem.friends == 0 ? "amigos" : "amigo" }}</h3>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import postComponent from "./postComponent.vue";
import { globalMethods } from '../js/globalMethods';
import api from '../configs/api.js';

export default {
    name: "usersComponent",
    mixins: [globalMethods],
    components: {
        postComponent
    },
    data() {
        return {
            formData: null,
            user: {},
            invitationSend: false,
            reload: false,
            is_friend: false,
            posts: [],
            showPostsVar: true,
            showFriendsVar: false,
            userId: null,
            is_blocked: false
        }
    },
    methods: {
        checkIfAccountMustBeDisplayed: function () {
            if (this.user.id != this.$root.user.id && this.user.visibility != "visible" || (this.user.blocked == 1 && this.user.requesting_user_has_blocked == 0)) {
                return false;
            }

            return true;
        },
        resetPage: function (user_id = null) {
            this.invitationSend = false;

            $("#show-posts").click();

            this.findUser(user_id);
        },
        resetSections: function () {
            $(".tabs li").removeClass("tab-active");

            this.showPostsVar = false;
            this.showFriendsVar = false;
        },
        showPosts: function (event) {
            this.resetSections();

            $(event.target).toggleClass("tab-active");

            this.showPostsVar = true;
        },
        showFriends: function (event) {
            this.resetSections();

            $(event.target).toggleClass("tab-active");

            this.showFriendsVar = true;
        },
        togglePhotoSend: function () {
            if (this.user.id != this.$root.user.id) return;

            let container = $(".change-photo");
            let wrapper = $(".photo-wrapper");

            if (container.is(":visible")) {
                container.hide();
                wrapper.hide();
            } else {
                container.show();
                wrapper.show();
            }
        },
        requestUploadImage: function () {
            $("#photo").click();
        },
        uploadPhoto: function (formData) {
            let self = this;

            api.post("/users/change_profile_photo", formData).then((response) => {
                self.user.profile_photo = response.data.returnObj;
            });
        },
        preSendPhoto: function (event) {
            let file = event.target.files.item(0);
            let self = this;

            self.formData = new FormData;

            if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") { // Se o arquivo tiver um desses formatos (PNG, JPG E JPEG), a imagem é exibida no modal e é permitida a requisição para o servidor, se não aparece a mensagem (arquivo não suportado).
                let adress = new FileReader();

                self.formData.set("user_image", file);
                adress.readAsDataURL(file);
                adress.onloadend = () => {
                    self.togglePhotoSend();
                    $("#submit-input").click();
                }
            }
        },
        requestUploadBanner: function () {
            $("#banner").click();
        },
        uploadBanner: function (formData) {
            let self = this;

            api.post("/users/change_profile_banner", formData).then((response) => {
                self.user.banner_photo = response.data.returnObj;
            });
            this.togglePhotoSend();
        },
        preSendBanner: function (event) {
            let file = event.target.files.item(0);
            let self = this;

            self.formData = new FormData;

            if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") { // Se o arquivo tiver um desses formatos (PNG, JPG E JPEG), a imagem é exibida no modal e é permitida a requisição para o servidor, se não aparece a mensagem (arquivo não suportado).
                let adress = new FileReader();

                self.formData.set("user_banner", file);
                adress.readAsDataURL(file);
                adress.onloadend = () => {
                    self.togglePhotoSend();
                    $("#submit-input-banner").click();
                }
            }
        },
        toggleAddButtonContainer: function () {
            let container = $(".button-container");
            let wrapper = $(".button-wrapper");

            if (this.invitationSend) return;

            if (container.is(":visible")) {
                container.hide();
                wrapper.hide();
            } else {
                if (!this.invitationSend && !this.is_friend && !this.is_blocked) {
                    this.sendFriendRequest();
                    this.invitationSend = true;
                    return;
                }

                container.show();
                wrapper.show();
            }
        },  
        findUser: function (user_id) {
            let self = this;

            let data = {
                id: user_id
            }
            
            api.post("/users/return_public_user", data).then((response) => {
                self.user = response.data.returnObj;
                self.returnUserPosts(user_id);

                setTimeout(() => {
                    $("#show-posts").click();
                }, 1)
            })
        },
        returnUserPosts: function (user_id) {
            let self = this;

            let data = {
                user_id: user_id
            }
            
            api.post("/users/return_feed", data).then((response) => {
                self.posts = response.data.returnObj;
            })
        },
        unfriend: function () {
            let data = {
                target_user: this.user.id
            }

            let self = this;

            self.toggleAddButtonContainer();

            api.post("/friends/unfriend", data).then(() => {
                self.reload = true;

                self.requireUser().then(() => {
                    self.$root.socket.send(JSON.stringify({ type: "reload_system" }));
                    self.reload = false;
                })
            })
        },
        sendFriendRequest: function () {
            if (this.user.blocked == 1) return;

            let data = {
                user_id: this.user.id,
                type: "friend_request"
            }

            this.$root.socket.send(JSON.stringify(data));
        },
        block: function () {
            let data = {
                target_user: this.user.id
            }

            let self = this;

            self.toggleAddButtonContainer();

            api.post("/friends/block", data).then(() => {
                self.reload = true;

                self.user.blocked = 1;
                self.user.requesting_user_has_blocked = 1;

                self.is_blocked = true;

                self.requireUser().then(() => {
                    self.$root.socket.send(JSON.stringify({ type: "reload_system" }));
                    self.reload = false;
                })
            })
        },
        unblock: function () {
            let data = {
                target_user: this.user.id
            }

            let self = this;

            self.toggleAddButtonContainer();

            api.post("/friends/unblock", data).then(() => {
                self.reload = true;

                self.user.blocked = 0;
                self.user.requesting_user_has_blocked = 0;

                setTimeout(() => {
                    self.reload = false;
                }, 1)
            })
        },
        returnAddButtonText: function () {
            let friends = this.$root.user.friend_ids;

            if (this.user.id == undefined) return;

            if (this.invitationSend) {
                return "Solicitação enviada";
            }

            if (friends.includes(this.user.id.toString())) {
                this.is_friend = true;
                return "Amigos";
            } else {
                this.is_friend = false;

                let text = "<span class='material-icons'>add</span> Adicionar amigo";

                if (this.user.blocked == 1 && this.user.requesting_user_has_blocked == 1) {
                    this.is_blocked = true;
                    text = "Bloqueado";
                }

                return text;
            }
        }
    },
    mounted: function () {
        this.userId = this.$route.params.id;

        this.findUser(this.userId);

        this.$watch('$route', () => {
            this.userId = this.$route.params.id;

            this.resetPage(this.userId);
        });
    }
}
</script>
<style scoped>
.tabs {
    width: 100%;
    border-bottom: 1px solid var(--purple-high);
    margin-bottom: var(--space-5);
}

    .tabs ul {
        display: flex;
        align-items: center;
    }

        .tabs ul li {
            color: var(--white);
            padding: var(--space-3) var(--space-5);
            border-radius: var(--radius-sm) var(--radius-sm) 0 0;
        }

            .tabs ul li:hover, .tab-active {
                color: var(--purple) !important;
                background: var(--pink);
            }

.user {
    display: flex;
    align-items: center;
    margin: var(--space-1) 0;
    padding: var(--space-3);
    border-radius: var(--radius-sm);
    cursor: pointer;
}

    .user a {
        display: flex;
        align-items: center;
        color: var(--white);
        text-decoration: none;
        width: 100%;
    }
</style>
<style scoped>

.user-page::-webkit-scrollbar {
  width: 7px;
}

.user-page::-webkit-scrollbar-track {
  background: transparent;
}

.user-page::-webkit-scrollbar-thumb {
  background: var(--pink);
  border-radius: var(--radius-sm);
}

.user-page {
    width: 100vw;
    max-width: 680px;
    padding: 0 var(--space-5);
    margin: auto;
    height: 100%;
    color: var(--white);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.banner {
    width: 100%;
    height: 270px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border-radius: var(--radius-sm);
    background-position: center center !important;
    position: relative;
}

.change-banner {
    position: absolute;
    right: var(--space-5);
    top: var(--space-5);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    background: var(--pink);
    color: var(--purple);
    cursor: pointer;
}

    .change-banner:hover {
        background: var(--pink-low);
    }

.user-inner img {
    border: var(--space-3) solid var(--purple);
    margin-top: -4rem;
    margin-left: var(--space-5);
    cursor: pointer;
    position: relative;
}

.image-container {
    position: relative;
}

.change-photo {
    background: var(--pink);
    text-align: center;
    padding: var(--space-3);
    border-radius: var(--radius-sm);
    display: none;
    position: absolute;
    bottom: -50px;
    width: 100%;
    border: 3px solid var(--purple);
    z-index: 4;
}

.photo-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: transparent;
    display: none;
}

.user-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-8);
}

.user-inner {
    display: flex;
    align-items: center;
}

.user-button {
    position: relative;
}

    .user-button button {
        display: flex;
        align-items: center;
    }

.button-container {
    position: absolute;
    background: var(--pink-mid);
    padding: var(--space-3);
    border-radius: var(--radius-sm);
    border: 3px solid var(--purple);
    top: 90%;
    display: none;
    z-index: 5;
    right: 0;
}

ul {
    list-style: none;
}

    ul li {
        padding: var(--space-3);
        cursor: pointer;
        color: var(--purple);
        white-space: nowrap;
    }

        ul li:hover {
            background: var(--pink-low);
        }

.button-wrapper {
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4;
    display: none;
}

@media (max-width: 517px) {
    .user-container .image-container img {
        width: 150px;
        height: 150px;
        min-width: 150px;
        min-height: 150px;
    }

    .user-container {
        flex-direction: column;
    }

    .user-inner {
        align-self: flex-start;
    }

    .user-button {
        align-self: flex-end;
    }

        .user-button button {
            margin: 0;
        }
}
</style>