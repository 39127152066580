<template>
    <div class="post" :id="'post-' + post.id">
        <div class="post-header">
            <div class="user-container">
                <router-link :to="'/dashboard/users/' + post.user_id">
                    <img :src="post.profile_photo" class="avatar-p">
                    <div class="post-informations">
                        <h3>{{ post.nickname }}</h3>
                        <p>{{ formatDate(post.create_date) }}</p>
                    </div>
                </router-link>
                <div class="options" v-if="post.user_id == $root.user.id">
                    <span class="material-icons" v-on:click="toogleMore(post.id)">more_vert</span>
                    <div class="more-container">
                        <ul>
                            <li v-on:click="excludePost(post.id)">Excluir post</li>
                            <li v-on:click="editPost(post.id)">Editar post</li>
                        </ul>
                    </div>
                </div>
                <div class="more-wrapper" v-on:click="toogleMore(post.id)"></div>
            </div>
        </div>
        <div class="post-body">
            <h3 v-if="!editing">{{ post_text }}</h3>
            <div class="edit-post" v-if="editing">
                <textarea id="edit-post" rows="4" v-on:keydown="countRows($event)" :value="post.post_text" v-on:keyup="countRows($event, true, editThisPost, post.id)"></textarea>
                <button type="button" class="btn btn-primary" v-on:click="editThisPost(post.id)">Salvar</button>
            </div>
            <div class="post-image" v-if="post.post_image != ''">
                <a :href="post.post_image" download>
                    <span class="material-icons download-photo-icon" title="Baixar foto">file_download</span>
                </a>
                <img :src="post.post_image">
            </div>
            <div class="reference-post" v-if="post.reference_post_id != null">
                <div class="user-container">
                    <router-link :to="'/dashboard/users/' + post.reference_post_user_id">
                        <img :src="post.reference_post_profile_photo" class="avatar-pp">
                        <div class="post-informations">
                            <h3>{{ post.reference_post_nickname }}</h3>
                            <p>{{ formatDate(post.reference_post_create_date) }}</p>
                        </div>
                    </router-link>
                </div>
                <div class="post-body">
                    <router-link :to="'/dashboard/post/' + post.reference_post_id">
                        <h3>{{ post.reference_post_text }}</h3>
                        <div class="post-image" v-if="post.reference_post_image != '' && post.reference_post_image != null">
                            <img :src="post.reference_post_image">
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="post-footer">
            <div class="footer-informations">
                <div class="information" v-on:click="likePost(post)" :class="post.user_liked == 1 ? 'user-liked' : ''">
                    <h3 class="number">{{ post.likes != 0 ? post.likes : "" }}</h3>
                    <span class="material-icons">favorite</span>
                    <p>{{ post.likes == 1 ? "Curtida" : "Curtidas" }}</p>
                </div>
                <div class="information" v-on:click="commentFocus(post)">
                    <h3 class="number">{{ post.comments != 0 ? post.comments : "" }}</h3>
                    <span class="material-icons">question_answer</span>
                    <p>{{ post.comments == 1 ? "Comentário" : "Comentários" }}</p>
                </div>
                <div class="information" v-on:click="sharePost(post)">
                    <h3 class="number">{{ post.sharings != 0 ? post.sharings : "" }}</h3>
                    <span class="material-icons">share</span>
                    <p>{{ post.sharings == 1 ? "Compartilhamento" : "Compartilhamentos" }}</p>
                </div>
            </div>
            <div class="comments-container">
                <div class="comment" v-for="(comment, index) in postComments" :key="index" :id="'comment-' + comment.id">
                    <div class="user">
                        <img :src="comment.profile_photo" class="avatar-pp">
                        <div class="user-informations">
                            <h3>{{ comment.nickname }}</h3>
                            <p>{{ formatDate(comment.create_date) }}</p>
                        </div>
                    </div>
                    <h3>{{ comment.comment }}</h3>
                </div>
                <div class="comment-input">
                    <textarea :id="'comment-input-' + post.id" rows="1" v-on:keydown="countRows($event)" v-on:keyup="countRows($event, true, makeComment, post)" placeholder="Digite seu comentário..." maxlength="2000"></textarea>
                    <span class="material-icons" v-on:click="makeComment(post)">send</span>
                </div>
            </div>
            <div class="share-container" :id="'share-post-' + post.id + '-modal'">
                <div class="share-modal" :style="post.post_image != '' ? 'height: calc(100vh - 125px);' : ''">
                    <div class="share-header">
                        <h3>Compartilhar publicação</h3>
                        <span class="material-icons" v-on:click="hideShareModal(post)">close</span>
                    </div>
                    <div class="share-body">
                        <textarea class="share-post-text" rows="5" placeholder="O que você está pensando?" v-on:keydown="countRowsExtended('share-post-' + post.id + '-modal .share-post-text', $event)" v-on:keyup="countRowsExtended('share-post-' + post.id + '-modal .share-post-text', $event, true)"></textarea>
                        <div class="reference-post">
                            <div class="user-container">
                                <a>
                                    <img :src="post.profile_photo" class="avatar-pp">
                                    <div class="post-informations">
                                        <h3>{{ post.nickname }}</h3>
                                        <p>{{ formatDate(post.create_date) }}</p>
                                    </div>
                                </a>
                            </div>
                            <div class="post-body">
                                <h3>{{ post.post_text }}</h3>
                                <div class="post-image" v-if="post.post_image != ''">
                                    <img :src="post.post_image">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="share-footer">
                        <button class="btn btn-primary share-button" v-on:click="sharePostAjax(post)">Compartilhar</button>
                    </div>
                </div>
                <div class="share-wrapper" v-on:click="hideShareModal(post)"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { globalMethods } from '../js/globalMethods';
import $ from 'jquery';
import moment from "moment";
import api from '../configs/api.js';

export default {
    name: "globalFeed",
    mixins: [globalMethods],
    props: ["post"],
    data() {
        return {
            editing: false,
            postComments: [],
            post_text: this.post.post_text
        }
    },
    watch: {
        "post.post_text": function () {
            this.post_text = this.post.post_text;
        },
        "post.id": function () {
            this.commentFromUrl();
        }
    },
    methods: {
        editThisPost: function (post_id) {
            let value = $("#edit-post").val();

            if (value.trim() == "") return;

            this.post_text = value;

            api.post("/posts/" + post_id + "/edit", { post_text: value }).then(() => {
                this.editing = false;
            })
        },
        commentFocus: function (post) {
            $("#comment-input-" + post.id).focus();

            this.returnPostComments(post);
        },
        makeComment: function (post) {
            let comment = $("#comment-input-" + post.id);
            let value = comment.val();

            if (value.trim() == "") return;

            let data = {
                post_id: post.id,
                creator_id: this.$root.user.id,
                create_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                comment: value,
                profile_photo: this.$root.user.profile_photo,
                nickname: this.$root.user.nickname
            }

            this.postComments.push(data);

            let dataSocket = {
                user_id: post.user_id,
                post_id: post.id,
                type: "post_comment",
                comment: value
            }

            this.$root.socket.send(JSON.stringify(dataSocket));

            comment.val("");
        },
        likePost: function (post) {
            if (post.user_liked == 1) return;

            $('#post-' + post.id + " .footer-informations .information:first-child").addClass("user-liked");

            let data = {
                user_id: post.user_id,
                post_id: post.id,
                type: "post_like"
            }

            this.$root.socket.send(JSON.stringify(data));
        },
        sharePostAjax: function (post) {
            if (post.user_id == this.$root.user.id) return;

            let new_text = $("#share-post-" + post.id + "-modal .share-post-text").val();
            
            if (new_text.trim() == "") {
                new_text = " ";
            }

            let data = {
                post_id: post.id,
                profile_photo: post.profile_photo,
                nickname: post.nickname,
                create_date: post.create_date,
                post_text: post.post_text,
                post_image: post.post_image,
                new_text: new_text,
                reference_user_id: post.user_id,
                type: "post_share"
            }

            let self = this;

            let shareButton = $("#share-post-" + post.id + "-modal .share-button");

            shareButton.attr("disabled", "disabled").addClass("btn-loading");

            this.$root.socket.send(JSON.stringify(data));

            setTimeout(() => {
                self.hideShareModal(post);
                shareButton.removeAttr("disabled").removeClass("btn-loading");
            }, 300)
        },
        showShareModal: function (post) {
            let shareModal = $("#share-post-" + post.id + "-modal");

            shareModal.css("display", "flex");

            shareModal.find("textarea").focus();
        },
        hideShareModal: function (post) {
            let shareModal = $("#share-post-" + post.id + "-modal");

            shareModal.hide();
        },
        sharePost: function (post) {
            if (post.user_id == this.$root.user.id) return;

            this.showShareModal(post);            
        },
        toogleMore: function (post_id) {
            let container = $("#post-" + post_id + " .more-container");
            let wrapper = $("#post-" + post_id + " .more-wrapper");

            if (container.is(":visible")) {
                container.hide();
                wrapper.hide();
            } else {
                container.show();
                wrapper.show();
            }
        },
        excludePost: function (post_id) {
            api.post("/posts/" + post_id + "/exclude").then(() => {
                $("#post-" + post_id).remove();
                this.toogleMore();
            });
        },
        editPost: function (post_id) {
            this.toogleMore(post_id);
            this.editing = true;

            setTimeout(() => {
                $("#edit-post").focus();
            }, 1)
        },
        returnPostComments: function (post) {
            return new Promise((resolve) => {
                let self = this;

                api.get("/posts/" + post.id + "/return_comments").then((results) => {
                    self.postComments = results.data.returnObj;

                    setTimeout(() => {
                        resolve();
                    }, 1)
                })
            })
        },
        commentFromUrl: function () {
            if (this.post.id == undefined) return;

            let url = new URLSearchParams(window.location.search);

            if (url.get("comment") == null || url.get("comment") == undefined || url.get("comment") == "") return;

            this.returnPostComments(this.post).then(() => {
                let comment = $("#comment-" + url.get("comment"));

                if (comment.length > 0) {
                    comment[0].scrollIntoView();
                }
            })
        }
    },
    mounted: function () {
        this.commentFromUrl();
    }
}
</script>
<style scoped>
.edit-post {
    display: flex;
    align-items: center;
    margin: var(--space-5) 0;
}

    .edit-post button {
        margin: 0;
    }

#edit-post {
    width: calc(100% - 73px);
    min-width: calc(100% - 73px);
    max-width: calc(100% - 73px);
    margin-right: var(--space-2);
}

.comments-container {
    margin-top: var(--space-3);
}

.comment {
    display: flex;
    flex-direction: column;
    margin: var(--space-6) 0;
}

    .comment .user {
        display: flex;
        align-items: center;
    }

    .comment > h3 {
        margin-top: var(--space-3);
        margin-left: 3.5rem;
        word-break: break-word;
    }

.comment-input {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: var(--space-5);
}

    .comment-input textarea {
        width: 100%;
        min-width: 100%;
    }

    .comment-input span {
        position: absolute;
        top: 0;
        bottom: 0;
        right: var(--space-5);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--purple);
        cursor: pointer;
    }
</style>
<style scoped>
.footer-informations {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--space-5);
}

.information {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: var(--space-3);
    border-radius: var(--radius-sm);
}

    .information:hover, .user-liked {
        background: var(--purple);
    }

    .information span {
        margin: 0 var(--space-3);
    }
</style>
<style scoped>
.post {
    background: var(--purple-high);
    padding: var(--space-5);
    border-radius: var(--radius-sm);
    margin-bottom: var(--space-5);
}

.user-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .user-container a {
        display: flex;
        align-items: center;
        color: var(--white);
        text-decoration: none;
    }

.post-body h3, .post-body a h3 {
    margin: var(--space-5) 0;
    color: var(--white);
    text-decoration: none !important;
    word-break: break-word;
}

.post-body a {
    text-decoration: none;
}

.post-image {
    position: relative;
}

    .post-image .download-photo-icon {
        position: absolute;
        top: var(--space-3);
        right: var(--space-3);
        color: var(--purple);
        background: var(--white);
        border-radius: var(--radius-full);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
        z-index: 3;
        cursor: pointer;
        width: 30px;
        height: 30px;
    }

        .post-image .download-photo-icon:hover {
            opacity: 1;
        }

    .post-image img {
        width: 100%;
        border-radius: var(--radius-sm);
        position: relative;
        z-index: 2;
    }

.more-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: transparent;
    display: none;
    z-index: 3;
}

.options {
    position: relative;
    z-index: 4;
}

    .options span {
        cursor: pointer;
    }

.more-container {
    background: var(--pink);
    border-radius: var(--radius-sm);
    color: var(--purple);
    position: absolute;
    right: var(--space-8);
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    display: none;
    height: fit-content;
}

    .more-container ul {
        list-style: none;
    }

        .more-container ul li {
            cursor: pointer;
            white-space: nowrap;
            padding: var(--space-3);
            border-radius: var(--radius-sm);
            margin: var(--space-2);
        }

            .more-container ul li:hover {
                background: var(--pink-low);
            }

@media (max-width: 517px) {
    .information p {
        display: none;
    }
}
</style>
<style scoped>
.share-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    display: none;
}

.share-modal {
    position: relative;
    z-index: 9999;
    width: 95vw;
    height: fit-content;
    max-width: 600px;
    max-height: 700px;
    background: var(--pink);
    border-radius: var(--radius-sm);
    color: var(--purple);
    overflow: hidden;
    border: 1px solid var(--pink);
    box-shadow: 0 0 25px rgba(244, 204, 204, 0.5);
}

    .share-modal textarea {
        border: none;
        width: 100%;
        resize: none;
    }

        .share-modal textarea:focus {
            outline: none;
        }

.share-body {
    overflow-y: auto;
    height: calc(100% - 143px);
}

.share-body::-webkit-scrollbar {
  width: 7px;
}

.share-body::-webkit-scrollbar-track {
  background: var(--purple);
}

.share-body::-webkit-scrollbar-thumb {
  background: var(--pink-low);
  border-radius: var(--radius-sm);
}

.share-footer {
    padding: 0 var(--space-5);
}

    .share-footer button {
        width: 100%;
    }
.reference-post {
    background: var(--purple);
    color: var(--white);
    padding: var(--space-5);
}

.share-header {
    padding: var(--space-5);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .share-header span {
        cursor: pointer;
        width: 35px;
        height: 35px;
        background: var(--pink-low);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--radius-full);
    }

.share-wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: var(--purple);
    opacity: 0.5;
}

.reference-post .post-informations h3 {
    margin: 0;
}
</style>