import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import indexPage from '../pages/indexPage.vue';
import registerPage from "../pages/registerPage.vue";
import loginPage from "../pages/loginPage.vue";
import dashboardPage from "../pages/dashboardPage.vue";
import globalFeed from "../components/globalFeed.vue";
import friendsFeed from "../components/friendsFeed.vue";
import myDiary from "../components/myDiary.vue";
import usersComponent from "../components/usersComponent.vue";
import singlePostComponent from "../components/singlePostComponent.vue";
import accountConfiguration from "../components/accountConfiguration.vue";

const routes = [
    {
        path: '/home',
        component: indexPage,
        meta: {
            title: "Yuume",
            metaTags: [
                {
                    name: "Yuume",
                    content: "Escreva sonhos"
                },
                {
                    name: "og:description",
                    content: "Escreva e compartilhe seus sonhos se quiser, ou crie seu próprio diário"
                }
            ]
        }
    },
    {
        path: "/register",
        component: registerPage,
        meta: {
            title: "Yuume - Registro",
            metaTags: [
                {
                    name: "Yuume - Registre-se",
                    content: "Junte-se à comunidade"
                },
                {
                    name: "og:description",
                    content: "Escreva e compartilhe seus sonhos se quiser, ou crie seu próprio diário"
                }
            ]
        }
    },
    {
        path: "/login",
        component: loginPage,
        meta: {
            title: "Yuume - Entrar",
            metaTags: [
                {
                    name: "Yuume - Entrar",
                    content: "Descubra um mundo novo"
                },
                {
                    name: "og:description",
                    content: "Escreva e compartilhe seus sonhos se quiser, ou crie seu próprio diário"
                }
            ]
        }
    },
    {
        path: "/dashboard",
        component: dashboardPage,
        meta: {
            title: "Yuume - Início",
            metaTags: [
                {
                    name: "Yuume - Início",
                    content: "Descubra um mundo novo"
                },
                {
                    name: "og:description",
                    content: "Escreva e compartilhe seus sonhos se quiser, ou crie seu próprio diário"
                }
            ]
        },
        children: [
            {
                path: '',
                component: globalFeed
            },
            {
                path: 'friends',
                component: friendsFeed
            },
            {
                path: 'my-diary',
                component: myDiary
            },
            {
                path: "users/:id",
                component: usersComponent
            },
            {
                path: "post/:id",
                component: singlePostComponent
            },
            {
                path: "account-config",
                component: accountConfiguration
            }
        ]
    },
    {
        path: '',
        redirect: '/home'
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

export default router;