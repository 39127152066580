<template>
    <div class="yume-home">
        <div class="left-content">
            <div class="logo">
                <img class="logo-lg" src="../assets/img/logo.png">
            </div>
            <div class="phrase">
                <h1>"Na Yuume, seus pensamentos são santuários protegidos, onde cada conexão é tecida com carinho e acolhimento, transformando solidão em comunidade.</h1>
            </div>
            <button type="button" class="btn btn-primary big-btn" v-on:click="goToDashboard()">Conecte-se</button>
        </div>
        <div class="right-content">
            <img src="../assets/img/draw-image.svg">
        </div>
        <footer>
            <p class="copy">© {{ year }} Yuume, todos os direitos reservados</p>
        </footer>
    </div>
</template>
<script>
export default {
    name: "indexPage",
    data() {
        return {
            year: null
        }
    },
    methods: {
        goToDashboard: function () {
            this.$router.push("/dashboard");
        }
    },
    mounted: function () {
        let now = new Date();
        this.year = now.getFullYear();
    }
}
</script>
<style scoped>
.yume-home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.right-content, .left-content {
    width: 50vw;
    padding: var(--space-5);
    margin: var(--space-5);
}

    .right-content img {
        width: 100%;
    }

.phrase {
    margin: var(--space-10) 0;
}

@media (max-width: 768px) {
    .right-content {
        display: none;
    }

    .left-content {
        width: 100%;
    }
}
</style>