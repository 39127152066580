<template>
    <div class="logon">
        <div class="photo" v-on:click="toggleLogonContainer()">
            <span class="material-icons">expand_more</span>
            <img :src="$root.user.profile_photo" class="avatar-p">
        </div>
        <h2>{{ $root.user.nickname }}</h2>
        <div class="logon-container">
            <ul>
                <li v-on:click="goToProfile()">Meu perfil</li>
                <li v-on:click="accountConfigurations()">Configurações da conta</li>
                <li v-on:click="logoff()">Sair</li>
            </ul>
        </div>
        <div class="logon-wrapper" v-on:click="toggleLogonContainer()"></div>
    </div>
</template>
<script>
import $ from 'jquery';
import { globalMethods } from '../js/globalMethods';

export default {
    name: "userLogon",
    mixins: [globalMethods],
    methods: {
        emit: function () {
            this.$emit("select");
        },
        logoff: function () {
            this.logoutUser();
        },
        goToProfile: function () {
            if (window.location.pathname.indexOf("/dashboard/users/" + this.$root.user.id) == -1) {
                this.$router.push("/dashboard/users/" + this.$root.user.id);
            }
            
            this.toggleLogonContainer();
            this.emit();
        }, 
        accountConfigurations: function () {
            if (window.location.pathname.indexOf("/dashboard/account-config") == -1) {
                this.$router.push("/dashboard/account-config");
            }

            this.toggleLogonContainer();
            this.emit();
        },
        toggleLogonContainer: function () {
            let container = $(".logon-container");
            let wrapper = $(".logon-wrapper");

            if (container.is(":visible")) {
                container.hide();
                wrapper.hide();
            } else {
                container.show();
                wrapper.show();
            }
        }
    }
}
</script>
<style scoped>
.logon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.photo {
    display: flex;
    align-items: center;
    color: var(--purple);
    cursor: pointer;
}

.logon-container {
    position: absolute;
    background: var(--pink-mid);
    padding: var(--space-3);
    border-radius: var(--radius-sm);
    border: 3px solid var(--purple);
    top: 100%;
    display: none;
    z-index: 5;
    left: 75px;
}

ul {
    list-style: none;
}

    ul li {
        padding: var(--space-3);
        cursor: pointer;
    }

        ul li:hover {
            background: var(--pink-low);
        }

.logon-wrapper {
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 4;
    display: none;
}

@media (max-width: 768px) {
    .logon h2, .logon span {
        display: none;
    }

    .logon img {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
    }

    .logon-container {
        left: initial;
        right: 0;
        width: fit-content;
    }
}
</style>