<template>
    <div class="post-image-container" v-on:click="selectPhoto()">
        <form class="send-photo" method="post" enctype="multipart/form-data" @submit.prevent="uploadPhoto(formData)">
            <div class="input-file-custom" v-if="previewPhoto == ''">
                <button type="submit" id="send-photo-button">
                    <span class="material-icons">image</span>
                </button>
            </div>
            <input type="file" name="photo" id="photo" @change.prevent="preSendPhoto($event)" title="Envie uma foto nos formatos PNG ou JPG">
            <input type="submit" id="send" style="display: none;" />
        </form>
        <span class="material-icons" v-if="previewPhoto != ''" id="clear-photo" v-on:click="cancelPhoto('#photo')">close</span>
        <div class="photo-preview" v-if="previewPhoto != ''">
            <img class="image-preview" :src="previewPhoto" alt="Pré-visualização da foto">
        </div>
    </div>
</template>
<script>
import $ from 'jquery';

export default {
    name: "postUploadContent",
    props: ["clear"],
    data() {
        return {
            previewPhoto: "",
            formData: null
        }
    },
    watch: {
        clear: function () {
            if (this.clear) {
                $("#clear-photo").click();
            }
        }
    },
    methods: {
        uploadPhoto: function (formData) {
            this.$emit("upload", formData);
        },
        preSendPhoto: function (event) {
            let file = event.target.files.item(0);
            let self = this;

            self.formData = new FormData;
            self.previewPhoto = "";

            if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") { // Se o arquivo tiver um desses formatos (PNG, JPG E JPEG), a imagem é exibida no modal e é permitida a requisição para o servidor, se não aparece a mensagem (arquivo não suportado).
                let adress = new FileReader();

                self.formData.set("post_image", file);
                adress.readAsDataURL(file);
                adress.onloadend = () => {
                    self.previewPhoto = adress.result;
                };

                $("#send").click();
            } else {
                self.previewPhoto = "";
            }
        },
        cancelPhoto: function (id) {
            $(id).val("");
            this.$emit("clear");
        },
        selectPhoto: function () {
            if (this.previewPhoto == "") {
                $("#photo").click();
            } else {
                this.previewPhoto = "";
            }
        }
    }
}
</script>
<style scoped>
.post-image-container {
    background: var(--pink);
    padding: var(--space-3);
    border-radius: var(--radius-sm);
    border: 2px solid var(--purple);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    z-index: 5;
}

    .post-image-container button {
        background: none;
        border: none;
        color: var(--purple);
        cursor: pointer;
    }

    .post-image-container > span {
        color: var(--purple);
        cursor: pointer;
        align-self: flex-end;
        font-weight: 700;
        margin-bottom: var(--space-3);
    }

input[type="file"] {
    display: none;
}

.photo-preview img {
    width: 100%;
}
</style>