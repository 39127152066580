<template>
    <div class="friends-feed">
        <div class="post-list">
            <postComponent :post="post" v-for="(post, index) in posts" :key="index" />
        </div>
    </div>
</template>
<script>
import { globalMethods } from '../js/globalMethods';
import postComponent from "./postComponent.vue";
import $ from 'jquery';
import api from '../configs/api.js';

export default {
    name: "friendsFeed",
    mixins: [globalMethods],
    components: {
        postComponent
    },
    methods: {
        makePost: function () {
            /*let element = $("#make-post");
            let text = element.val();
            let image = this.postImage;*/
        },
        togglePostInput: function () {
            let element = $("#make-post");
            let wrapper = $(".make-post-wrapper");

            if (element.css("min-height") == "200px") {
                element.css("min-height", "40px");
                wrapper.hide();
            } else {
                element.css("min-height", "200px");
                wrapper.show();
            }
        },
        returnFeed: function () {
            let self = this;

            if (this.noMorePostsToLoad) return;

            api.get("/posts/return_friends_feed?offset=" + this.offset + "&limite=20").then((results) => {
                if (results.data.returnObj.length == 0) {
                    self.noMorePostsToLoad = true;
                } else {
                    self.posts = self.posts.concat(results.data.returnObj);
                    self.offset += self.posts.length;
                }

                self.loadMore = false;
            })
        }
    },
    mounted: function () {
        this.returnFeed();
    },
    data() {
        return {
            postImage: "",
            posts: [],
            noMorePostsToLoad: false,
            offset: 0,
            loadMore: false,
        }
    }
}
</script>
<style scoped>
.friends-feed {
    width: 100vw;
    max-width: 680px;
    padding: var(--space-5);
    margin: auto;
    height: 100vh;
    color: var(--white);
    overflow-y: auto;
    height: 100vh;
}

@media (max-width: 1440px) {
    .friends-feed {
        height: 100%;
    }
}

.friends-feed > div {
    width: 100%;
}

.friends-feed::-webkit-scrollbar {
  width: 7px;
}

.friends-feed::-webkit-scrollbar-track {
  background: transparent;
}

.friends-feed::-webkit-scrollbar-thumb {
  background: var(--pink);
  border-radius: var(--radius-sm);
}
</style>