<template>
    <div class="account-configuration">
        <h1>Configurações da conta</h1>
        <div class="configurations-container">
            <form @submit.prevent="editAccount()" id="account-form">
                <div class="grouping">
                    <h3>Exibição</h3>
                    <div class="form-group">
                        <label for="nickname">Nick</label>
                        <input type="text" id="nick" name="nickname" v-model="$root.user.nickname" maxlength="50" required>
                    </div>
                </div>
                <div class="grouping">
                    <h3>Segurança da conta</h3>
                    <div class="form-group">
                        <label for="old-password">Senha antiga</label>
                        <input type="password" id="old-password" name="old_password" maxlength="100">
                    </div>
                    <div class="form-group">
                        <label for="password">Nova senha</label>
                        <input type="password" id="password" name="password" maxlength="100">
                        <p class="response error">{{ response }}</p>
                    </div>
                </div>
                <div class="grouping">
                    <h3>Visibilidade</h3>
                    <div class="form-group radio-group">
                        <label for="visibility-on">Perfil público (qualquer pessoa pode ver minhas publicações)</label>
                        <input type="radio" id="visibility-on" v-model="$root.user.visibility" value="visible" name="visibility" required>
                    </div>
                    <div class="form-group radio-group">
                        <label for="visibility-off">Perfil privado (somente meus amigos podem ver minhas publicações)</label>
                        <input type="radio" id="visibility-off" v-model="$root.user.visibility" value="hidden" name="visibility" required>
                    </div>
                </div>
                <p class="response2 error">{{ response2 }}</p>
                <button type="submit" class="btn btn-primary btn-100">Salvar</button>
            </form>
            <div class="grouping">
                <h3>Gerenciamento da conta</h3>
                <div class="form-group">
                    <button type="button" class="btn btn-red" v-on:click="excludeAccount()">Encerrar minha conta</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { globalMethods } from '../js/globalMethods';
import api from '../configs/api.js';

export default {
    name: "accountConfiguration",
    mixins: [globalMethods],
    data() {
        return {
            account: {
                id: 0,
                nickname: "Ana clara",
                visibility: "visible"
            },
            response2: ""
        }
    },
    methods: {
        excludeAccount: function () {
            let bConfirm = confirm("Tem certeza que deseja excluir sua conta? (Esta ação é irreversível)");

            this.response2 = "";
            $(".response2").removeClass("error").removeClass("success");

            if (bConfirm) {
                let self = this;

                api.post("/users/exclude_account").then(() => {
                    self.logoutUser();
                }).catch((error) => {
                    this.response2 = error.data.message;
                    $(".response2").addClass("error");
                })
            } 
        },
        editAccount: function () {
            let self = this;

            this.resetResponse();
            this.response2 = "";
            $(".response2").removeClass("success").removeClass("error");

            if ($("#old-password").val().trim() == "" && $("#password").val().trim() != "" || $("#old-password").val().trim() != "" && $("#password").val().trim() == "") {
                this.setResponse("Defina uma nova senha", "error");
                return;
            }

            let data = $("#account-form").serializeArray().reduce(function (obj, item) { // Pega todos os dados do formulário e coloca em um objeto.
                obj[item.name] = item.value;
                return obj;
            }, {});

            api.post("/users/update_account", data).then((response) => {
                if (response.data.returnObj.changed_password) {
                    self.setResponse("Senha alterada com sucesso", "success-green");
                    self.setJwtInLocalStorage(response.data.returnObj.token);
                } else {
                    self.setResponse("A senha não foi alterada", "alert");
                }

                this.response2 = "Dados alterados";
                $(".response2").addClass("success-green");
            }).catch((error) => {
                this.response2 = error.data.message;
                $(".response2").addClass("error");
            })
        }
    }
}
</script>
<style scoped>
.account-configuration {
    width: 100vw;
    max-width: 680px;
    padding: var(--space-5);
    margin: auto;
    height: 100vh;
    color: var(--white);
    overflow-y: auto;
}

@media (max-width: 1440px) {
    .account-configuration {
        height: 100%;
    }
}

.grouping {
    margin: var(--space-10) 0;
}
</style>