<template>
    <div class="chat">
        <div class="no-friends" v-if="friends.length == 0">
            <h3>Você ainda não tem amigos.</h3>
        </div>
        <div class="user" :class="user.online == 1 ? 'online' : ''" v-for="(user, index) in friends" :key="index" v-on:click="openChat(user)" :id="'user-' + user.id">
            <div class="image-container">
                <img :src="user.profile_photo" class="avatar-p">
            </div>
            <h2>{{ user.nickname }}</h2>
        </div>        
        <div class="chat-box">
            <div class="chat-header">
                <div class="user-container">
                    <router-link :to="'/dashboard/users/' + selected.id">
                        <img :src="selected.profile_photo" class="avatar-pp">
                        <div class="text-container">
                            <h2>{{ selected.nickname }}</h2>
                            <h3>{{ selected.online == 1 ? "online agora" : "" }}</h3>
                        </div>
                    </router-link>
                </div>
                <span class="material-icons" v-on:click="hideChat()">close</span>
            </div>
            <div class="chat-body">
                <div class="new-messages-alert" v-if="new_messages_alert">
                    <h3>Você tem novas mensagens</h3>
                </div>
                <div class="messages-container">
                    <div class="no-messages" v-if="messages.length == 0">
                        <div class="user" :class="selected.user_status == 'online' ? 'online' : ''">
                            <div class="image-container">
                                <img :src="selected.profile_photo" class="avatar-p">
                            </div>
                        </div>
                        <h3>Este é o início da sua conversa com <br> {{ selected.nickname }}</h3>
                    </div>
                    <div class="message" v-for="(message, index) in messages" :class="$root.user.id == message.sender_id ? 'out' : 'in'" :key="index" :id="'message-' + (index + 1)">
                        <div class="message-header">
                            <div class="user-container">
                                <img :src="message.sender_profile_photo" class="avatar-xs">
                                <h3>{{ message.sender_name }}</h3>
                            </div>
                            <div class="message-details">
                                <p :title="message.send_date">{{ formatDate(message.send_date) }}</p>
                                <span class="material-icons" :style="message.view_date != '' ? 'color: #50a6ff;' : ''">done_all</span>
                            </div>
                        </div>
                        <div class="message-body">
                            <h3>{{ message.message }}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-footer">
                <textarea name="message" id="message-input" rows="1" v-on:keydown="countRows($event)" v-on:keyup="countRows($event, true, sendMessage)" placeholder="Digite sua mensagem..." maxlength="5000"></textarea>
                <span class="material-icons" v-on:click="sendMessage()">send</span>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { globalMethods } from '../js/globalMethods';
import moment from 'moment';
import api from '../configs/api.js';
import audioSrc from "../assets/audio/new_message_alert.mp3";

export default {
    name: "chatComponent",
    mixins: [globalMethods],
    props: ["forceClose"],
    data() {
        return {
            have_new_messages: false,
            new_messages_alert: false,
            friends: [],
            selected: {},
            messages: [],
            isResponsive: false
        }
    },
    watch: {
        selected: function () {
            if (this.selected.id != undefined) {
                this.showChat();
            }
        },
        forceClose: function () {
            if (this.forceClose) {
                this.hideChat();
            }
        }
    },
    methods: {
        sendMessage: function (index = null) {
            let message = $("#message-input").val();

            if (message.trim() == "") return;

            let new_message = {
                id: this.messages.length + 1,
                sender_id: this.$root.user.id,
                receiver_id: this.selected.id,
                sender_name: this.$root.user.nickname,
                sender_profile_photo: this.$root.user.profile_photo,
                receiver_name: this.selected.nickname,
                send_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                view_date: "",
                message: message
            }

            this.messages.push(new_message);

            let last_message_obj = {
                friend: this.selected.id,
                messages_length: this.messages.length
            }

            this.storeLastChatInLocalStorage(last_message_obj);

            this.$root.socket.send(JSON.stringify(new_message));

            setTimeout(() => {
                $(".chat-body").scrollTop(999999999999999);
            }, 1)

            $("#message-input").val(index);
            $("#message-input").val("");
        },  
        storeLastChatInLocalStorage: function (obj) {
            let last_chat = localStorage.getItem("last_chat");
            
            if (last_chat == null || last_chat == undefined || last_chat == "") {
                let array = [];
                array.push(obj);

                localStorage.setItem("last_chat", JSON.stringify(array));
            } else {
                let last_chat_array = JSON.parse(last_chat);

                let index = last_chat_array.findIndex(item => item.friend == obj.friend);

                if (index != -1) {
                    last_chat_array[index].messages_length = obj.messages_length;
                } else {
                    last_chat_array.push(obj);
                }

                localStorage.setItem("last_chat", JSON.stringify(last_chat_array));
            }
        },
        showChat: function () {
            let chat = $(".chat-box");

            chat.css("display", "flex");

            this.returnMessages();

            setTimeout(() => {
                chat.css("opacity", 1);
                $("#message-input").focus();
            }, 1)
        },
        hideChat: function () {
            let chat = $(".chat-box");

            chat.css("opacity", 0);
            
            setTimeout(() => {
                chat.hide();
                this.closeChat();
            }, 400)
        },
        openChat: function (target) {
            this.selected = target;
        },
        closeChat: function () {
            this.selected = {};
            this.messages = [];
        },
        viewMessages: function () {
            this.$root.socket.send(JSON.stringify({ type: 'message_viewed', senderId: this.selected.id }));
        },  
        checkIfHaveNewMessagesOutVision: function () {
            let container = $(".chat-body");

            let isScrollAtBottom = container.scrollTop() >= container.prop('scrollHeight') - container.innerHeight();
            
            if (this.have_new_messages && !isScrollAtBottom) {
                this.new_messages_alert = true;
            } else if (this.have_new_messages && isScrollAtBottom) {
                setTimeout(() => {
                    $(".chat-body").scrollTop(999999999999999);
                }, 1)
            }

            if (isScrollAtBottom) {
                this.new_messages_alert = false;
            }

            this.have_new_messages = false;
        },
        returnMessages: function () {
            let self = this;

            let data = {
                destiny_user_id: this.selected.id
            }

            api.post("/chat/return_messages", data).then((response) => {
                self.messages = response.data.returnObj.messages;
                setTimeout(() => {
                    $(".chat-body").scrollTop(999999999999999);
                    self.checkNotReadMessages();
                    self.viewMessages();
                    self.wsReceiveMessages();

                    let last_message_obj = {
                        friend: self.selected.id,
                        messages_length: self.messages.length
                    }

                    self.storeLastChatInLocalStorage(last_message_obj);

                    self.setUserNewMessages(self.selected.id, true);
                }, 1)
            }).catch((error) => {
                console.log(error.data);
            })
        },  
        returnFriends: function () {
            let self = this;

            api.get("/chat/return_users").then((response) => {
                self.friends = response.data.returnObj.friends;

                setTimeout(() => {
                    self.wsFriendsListNewMessages();
                    self.wsFriendsStatus();
                    self.loadUserNewMessages();
                }, 1)
            })
        },
        checkNotReadMessages: function () {
            let last_chat_array = JSON.parse(localStorage.getItem("last_chat")) || [{ friend: this.selected.id, messages_length: this.messages.length }];

            let index = last_chat_array.findIndex(item => item.friend == this.selected.id);

            let lastMessage = last_chat_array[index == -1 ? 0 : index].messages_length;

            let new_messages_element = `
                <p id="unread-messages">Mensagens não lidas</p>
            `

            if (this.messages.length != parseInt(lastMessage)) {
                if ($("#message-" + lastMessage)[0] != undefined) {
                    $("#message-" + lastMessage)[0].scrollIntoView();
                }
                
                $(new_messages_element).insertAfter($("#message-" + lastMessage));
            } else {
                $("#unread-messages").remove();
                this.new_messages_alert = false;
            }
        },
        wsFriendsStatus: function () {
            let self = this;

            self.$root.socket.addEventListener("message", function (event) {
                let message = JSON.parse(event.data);
                
                if (message.type == "online_friend" && self.friends.length > 0) {
                    let friendIndex = self.friends.findIndex(friend => friend.id == message.friend);

                    self.friends[friendIndex].online = 1;
                }

                if (message.type == "offline_friend" && self.friends.length > 0) {
                    let friendIndex = self.friends.findIndex(friend => friend.id == message.friend);

                    self.friends[friendIndex].online = 0;
                }
            });
        },  
        loadUserNewMessages: function () {
            let new_messages = JSON.parse(localStorage.getItem("new_messages"));

            if (new_messages != null && new_messages != undefined && new_messages != "") {
                for (let i = 0; i < new_messages.length; i++) {
                    let current = new_messages[i];
                    
                    $("#user-" + current).addClass("user-new-messages");
                }
            }
        },
        setUserNewMessages: function (user_id, remove = false) {
            let new_messages = JSON.parse(localStorage.getItem("new_messages"));
            
            if (user_id == null) return;

            if ((new_messages == null || new_messages == undefined || new_messages == "" || new_messages.length == 0) && !remove) {
                let array = [];
                array.push(user_id);

                localStorage.setItem("new_messages", JSON.stringify(array));

                $("#user-" + user_id).addClass("user-new-messages");
            } else {
                let new_messages_array = new_messages;

                let index = -1;

                if (new_messages_array != null) {
                    index = new_messages_array.indexOf(user_id);
                }

                if (index == -1 && !remove) {
                    new_messages_array.push(user_id);
                    $("#user-" + user_id).addClass("user-new-messages");
                } else {
                    if (remove && new_messages_array != null) {
                        new_messages_array.splice(index, 1);
                        $("#user-" + user_id).removeClass("user-new-messages");
                    }
                }

                localStorage.setItem("new_messages", JSON.stringify(new_messages_array));
            }
        },
        wsFriendsListNewMessages: function () {
            let self = this;
            
            self.$root.socket.addEventListener("message", function (event) {
                let message = JSON.parse(event.data);

                if (message.receiver_id == self.$root.user.id && self.selected.id == undefined) {
                    self.setUserNewMessages(message.sender_id);
                } else {
                    self.setUserNewMessages(message.sender_id, true);
                    $("#user-" + message.sender_id).removeClass("user-new-messages");
                }
            });
        },
        wsReceiveMessages: function () {
            let self = this;

            self.$root.socket.addEventListener("message", function (event) {
                let message = JSON.parse(event.data);

                if (message.type === 'message_viewed') { //Sempre que o evento for desse tipo, atualiza as mensagens no objeto com view_date
                    self.messages.forEach((item) => {
                        item.view_date = moment().format("YYYY-MM-DD HH:mm:ss");
                    })
                }

                if (message.sender_id == self.selected.id) { //Se for mensagem que voce recebeu, manda o evento de view
                    self.messages.push(message);
                    self.$root.socket.send(JSON.stringify({ type: 'message_viewed', senderId: self.selected.id }));

                    let audio = new Audio(audioSrc);
                    audio.play();

                    self.have_new_messages = true;
                    self.checkIfHaveNewMessagesOutVision();

                    let last_message_obj = {
                        friend: self.selected.id,
                        messages_length: self.messages.length
                    }

                    self.storeLastChatInLocalStorage(last_message_obj);
                }
            });
        },
        wsReloadSystem: function () {
            let self = this;

            self.$root.socket.addEventListener("message", function (event) {
                let message = JSON.parse(event.data);

                if (message.type == "reload_system") {
                    self.returnFriends();
                }
            });
        }
    },
    mounted: function () {
        this.wsReloadSystem();
        this.returnFriends();

        $(".chat-body").on("scroll", () => {
            this.checkIfHaveNewMessagesOutVision();            
        })
    }
}
</script>
<style scoped>
.message-header, .message-details {
    display: flex;
    align-items: center;
}

.message-details {
    margin: 0 var(--space-3);
}

    .message-details span {
        font-size: var(--fontsize-sm);
        margin: 0 var(--space-3);
    }

.message-body > h3 {
    margin-top: var(--space-4);
}

.new-messages-alert {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4rem;
    margin: auto;
    width: fit-content;
    padding: var(--space-3);
    background: var(--pink);
    color: var(--purple);
    border-radius: vaR(--radius-sm);
}

.message {
    margin: var(--space-5) 0;
    padding: var(--space-3);
    background: var(--purple-high);
    border-radius: var(--radius-sm);
}

.message:first-child {
    margin-top: 0;
}

.out, .out .user-container {
    background: var(--pink);
    color: var(--purple) !important;
}

.in .message-details span {
    display: none;
}

.user-container {
    display: flex;
    align-items: center;
}
</style>
<style scoped>

.no-friends {
    padding: vaR(--space-5);
    text-align: center;
}

.no-messages {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.chat {
    background: var(--pink-mid);
    border: 3px solid var(--purple);
    border-radius: var(--radius-sm);
    padding: var(--space-3);
    height: fit-content;
    max-height: 70vh;
    overflow-y: auto;
    width: 100vw;
    min-width: 200px;
    max-width: 338px;
}

.chat::-webkit-scrollbar {
  width: 7px;
}

.chat::-webkit-scrollbar-track {
  background: transparent;
}

.chat::-webkit-scrollbar-thumb {
  background: var(--purple);
  border-radius: var(--radius-sm);
}

.user {
    display: flex;
    align-items: center;
    margin: var(--space-1) 0;
    padding: var(--space-3);
    border-radius: var(--radius-sm);
    cursor: pointer;
    position: relative;
}

    .user:hover {
        background: var(--pink-low);
    }

    .user h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .user.user-new-messages::after {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--red);
        position: absolute;
        right: var(--space-3);
        top: 0;
        bottom: 0;
        margin: auto;
        animation-name: pulseRed;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
    }

@keyframes pulseRed {
    0% {
        box-shadow: 0 0 0 1px rgba(255, 55, 55, 0.836);
    }
    100% {
        box-shadow: 0 0 0 15px rgba(238, 238, 238, 0);
    }
}


.user.online .image-container::after {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: var(--radius-full);
    background: rgb(17, 202, 17);
    position: absolute;
    z-index: 2;
    bottom: 6px;
    right: 18px;
}

.image-container {
    position: relative;
}

.messages-container .image-container, .messages-container .user, .messages-container .user img {
    margin: 0 !important;
    cursor: default;
}

    .messages-container .image-container:hover, .messages-container .user:hover {
        background: transparent !important;
    }

    .messages-container .user .image-container:after {
        right: 0 !important;
    }

.chat-box {
    position: fixed;
    bottom: .5rem;
    right: 2rem;
    background: var(--purple);
    width: 90vw;
    max-width: 400px;
    border: 3px solid var(--pink);
    height: 100%;
    max-height: 500px;
    border-radius: var(--radius-sm);
    display: none;
    opacity: 0;
    transition: opacity 0.4s;
    flex-direction: column;
    z-index: 3;
}

@media (max-width: 768px) {
    .chat-box {
        max-height: 100%;
    }
}

.chat-header, .user-container a {
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}

.chat-header span {
    cursor: pointer;
}

.chat-header, .chat-body, .chat-footer {
    padding: var(--space-4);
}

.chat-body {
    flex: 1;
    padding: var(--space-5);
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--white);
    overflow-y: auto;
}

.chat-body::-webkit-scrollbar {
  width: 7px;
}

.chat-body::-webkit-scrollbar-track {
  background: transparent;
}

.chat-body::-webkit-scrollbar-thumb {
  background: var(--pink);
  border-radius: var(--radius-sm);
}

.chat-footer {
    position: relative;
    display: flex;
    align-items: center;
}

    .chat-footer textarea {
        width: calc(100% - 55px);
        resize: none;
        min-height: 40px;
        max-height: 100px;
        padding-right: var(--space-10);
    }

    .chat-footer span {
        position: absolute;
        color: var(--purple);
        right: var(--space-6);
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: var(--pink);
        height: calc(100% - 28px);
        border-radius: var(--radius-sm);
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
<style scoped>
@media (max-width: 768px) {
    .chat {
        width: 100vw;
        max-width: 100vw;
        left: 0;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
    }

    .chat-box {
        width: 100vw;
        max-width: 100vw;
        left: 0;
        top: 72px;
        height: calc(100% - 72px);
    }
}
</style>