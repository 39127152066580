<template>
    <div class="single-post-container">
        <postComponent :post="post" />
    </div>
</template>
<script>
import postComponent from "./postComponent.vue";
import api from '../configs/api.js';

export default {
    name: "singlePostComponent",
    data() {
        return {
            post: {}
        }
    },
    methods: {
        returnPost: function (post_id) {
            let self = this;

            api.get("/posts/" + post_id + "/return_post").then((results) => {
                self.post = results.data.returnObj;

                if (self.post == undefined) {
                    self.$router.push("/dashboard");
                }
            })
        }
    },
    mounted: function () {
        if (this.$route.params.id != null && this.$route.params.id != undefined) {
            this.returnPost(this.$route.params.id);
        }
    },
    components: {
        postComponent
    }
}
</script>
<style scoped>
.single-post-container {
    width: 100vw;
    max-width: 680px;
    padding: 0 var(--space-5);
    margin: auto;
    height: 100vh;
    color: var(--white);
    overflow-y: auto;
}

@media (max-width: 768px) {
    .single-post-container {
        height: calc(100vh - 130px);
    }
}

.single-post-container::-webkit-scrollbar {
  width: 7px;
}

.single-post-container::-webkit-scrollbar-track {
  background: transparent;
}

.single-post-container::-webkit-scrollbar-thumb {
  background: var(--pink);
  border-radius: var(--radius-sm);
}
</style>