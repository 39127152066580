<template>
    <div class="dashboard" v-if="!loading">
        <div class="responsive-header" v-if="responsive">
            <responsiveHeader />
        </div>
        <div class="left-content">
            <div class="header">
                <img src="../assets/img/logo.png" class="logo">
                <notificationsComponent v-if="!responsive" />
            </div>
            <div class="card">
                <userCard />
            </div>
            <div class="menu">
                <menuComponent />
            </div>
        </div>
        <div class="slot">
            <router-view path="$router.key" />
        </div>  
        <div class="right-content">
            <div class="user-logon">
                <userLogon />
            </div>
            <div class="chat-container" v-if="!chat_responsive">
                <chatComponent />
            </div>
        </div>
    </div>
</template>
<script>
import { globalMethods } from '../js/globalMethods';
import notificationsComponent from "../components/notificationsComponent.vue";
import userCard from "../components/userCard.vue";
import responsiveHeader from "../components/responsiveHeader.vue";
import chatComponent from "../components/chatComponent.vue";
import menuComponent from "../components/menuComponent.vue";
import userLogon from "../components/userLogon.vue";
import moment from 'moment';
import api from '../configs/api.js';

export default {
    nama: "dashboardPage",
    mixins: [globalMethods],
    data() {
        return {
            user: {},
            loading: true,
            socket: null,
            responsive: false,
            chat_responsive: false
        }
    },
    components: {
        notificationsComponent,
        userCard,
        responsiveHeader,
        chatComponent,
        menuComponent,
        userLogon
    },
    methods: {
        initSystemRequests: function () {
            let self = this;

            self.requireUser().then(() => {
                self.loading = false;

                self.initWebSocket();
            })
        },
        initWebSocket: function () {
            let replacedUrlApi = api.defaults.baseURL.replace("http://", "").replace("https://", "");
            this.$root.socket = new WebSocket('wss://' + replacedUrlApi + "?token=" + this.getJwtInLocalStorage());

            let self = this;
            self.$root.socket.onopen = function() {
                self.$root.socket.send(JSON.stringify({ type: 'online', friends: self.$root.user.friend_ids }));
            };
        }
    },
    mounted: function () {
        let self = this;
        
        this.checkIfUserIsAuthenticated().then(() => {
            let interval = setInterval(() => {
                if (self.$root.jwtLoaded) {
                    clearInterval(interval);
                    
                    self.initSystemRequests();
                }
            })
        }).catch(() => {})

        moment.locale('pt-br');

        window.addEventListener('beforeunload', function() {
            if (self.$root.socket != undefined) {
                self.$root.socket.send(JSON.stringify({ type: 'offline', friends: self.$root.user.friend_ids }));

                self.$root.socket.close();
            }
        });

        if (window.innerWidth <= 1440) {
            self.responsive = true;
        } else {
            self.responsive = false;
        }

        if (window.innerWidth <= 1050) {
            self.chat_responsive = true;
        } else {
            self.chat_responsive = false;
        }

        window.addEventListener('resize', function() {
            if (window.innerWidth <= 1440) {
                self.responsive = true;
            } else {
                self.responsive = false;
            }

            if (window.innerWidth <= 1050) {
                self.chat_responsive = true;
            } else {
                self.chat_responsive = false;
            }
        })
    }
}
</script>
<style scoped> 
.dashboard {
    display: grid;
    grid-template-columns: 370px calc(100vw - 740px) 370px; /* Distribuição de largura desejada */
    grid-template-rows: 100vh; /* Definindo a altura total da grid para a altura da viewport */
}

.left-content, .right-content {
    display: flex;
    flex-direction: column;
    padding: var(--space-5);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card, .header, .chat-container, .user-logon {
    margin: var(--space-5) 0;
}

@media (min-width: 1050px) {
    .chat-container {
        display: block !important;
    }
}

.slot {
    background: var(--purple);
    padding: var(--space-5) 0;
}

.responsive-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 70px;
    display: none;
    z-index: 7;
}

@media (max-width: 1440px) {
    .left-content {
        display: none;
    }

    .dashboard {
        grid-template-columns: calc(100vw - 370px) 370px;
    }

    .responsive-header {
        display: block;
    }

    .slot, .right-content {
        padding-top: 70px !important;
        padding-bottom: 0 !important;
    }
}

@media (max-width: 1050px) {
    .right-content {
        display: none;
    }

    .dashboard {
        grid-template-columns: 100vw;
    }
}
</style>