<template>
    <div class="yume-register">
        <div class="register-container">
            <h1>Junte-se</h1>
            <div class="form-container">
                <form @submit.prevent="register()" id="register-form">
                    <div class="form-group">
                        <label for="nickname">Nick</label>
                        <input type="text" id="nickname" name="nickname" maxlength="50" required>
                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" id="email" name="email" maxlength="100" required>
                    </div>
                    <div class="form-group">
                        <label for="password">Senha</label>
                        <input type="password" id="password" name="password" maxlength="100" required>
                    </div>
                    <div class="form-group">
                        <label for="repeat-password">Repita a senha</label>
                        <input type="password" id="repeat-password" maxlength="100" required>
                    </div>
                    <button type="submit" class="btn btn-primary btn-100" id="register-button">Registro</button>
                    <h3 class="response">{{ response }}</h3>
                    <h3>Já é membro? <router-link to="/login">Entrar</router-link></h3>
                </form>
                <div class="phrase">
                    <h2>Conheça Yuume: Onde pensamentos são protegidos e conexões são feitas com acolhimento.</h2>
                </div>
            </div>
        </div>
        <footer>
            <p class="copy">© {{ year }} Yuume, todos os direitos reservados</p>
        </footer>
    </div>
</template>
<script>
import { globalMethods } from '../js/globalMethods';
import $ from 'jquery';
import api from '../configs/api.js';

export default {
    name: "indexPage",
    mixins: [globalMethods],
    data() {
        return {
            year: null
        }
    },
    methods: {
        register: function () {
            let self = this;

            let registerButton = $("#register-button");
            registerButton.attr("disabled", "disabled").addClass("btn-loading");

            let data = $("#register-form").serializeArray().reduce(function (obj, item) { // Pega todos os dados do formulário e coloca em um objeto.
                obj[item.name] = item.value;
                return obj;
            }, {});

            let password = $("#password").val();
            let repeatPassword = $("#repeat-password").val();

            if (password == repeatPassword) {
                api.post("/users/register", data).then((res) => {
                    self.setResponse(res.data.message, "success");
                    self.logoutUser();
                }).catch((error) => {
                    self.setResponse(error.response.data, "error");
                }).then(() => {
                    registerButton.removeAttr("disabled").removeClass("btn-loading");
                })
            } else {
                self.setResponse("As senhas não coincidem", "error");
                registerButton.removeAttr("disabled").removeClass("btn-loading");
            }
        }
    },
    mounted: function () {
        let now = new Date();
        this.year = now.getFullYear();
    }
}
</script>
<style scoped>
.yume-register {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.register-container {
    background: var(--pink-mid);
    border: 3px solid var(--purple);
    width: 95vw;
    max-width: 1000px;
    min-width: 200px;
    height: fit-content;
    padding: var(--space-6);
    border-radius: var(--radius-sm);
    display: flex;
    flex-direction: column;
    margin-top: var(--space-10);
}

.phrase, form {
    width: calc(50% - var(--space-6));
    margin: var(--space-6) 0;
}

@media (max-width: 768px) {
    .form-container {
        flex-direction: column;
    }

    .phrase, form {
        width: 100%;
    }
}
</style>