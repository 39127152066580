<template>
    <header>
        <div class="grouping">
            <router-link to="/dashboard" @click.native="closeMenu()">
                <img src="../assets/img/logo.png" class="logo">
            </router-link>
            <span class="material-icons" v-on:click="toggleMenu()">menu</span>
            <div class="menu">
                <menuComponent @select="toggleMenu(); closeChat();" />
            </div>
        </div>
        <div class="grouping">
            <span class="material-icons chat-icon" v-on:click="toggleChat()">chat</span>
            <div class="chat-container" v-if="responsiveChat">
                <chatComponent :forceClose="forceCloseChat" />
            </div>
            <notificationsComponent />
            <div class="user-logon" v-if="responsiveChat">
                <userLogon @select="closeChat()" />
            </div>
        </div>
        <div class="chat-wrapper" v-on:click="toggleChat()"></div>
        <div class="menu-wrapper" v-on:click="toggleMenu()"></div>
    </header>
</template>
<script>
import notificationsComponent from "../components/notificationsComponent.vue";
import chatComponent from "../components/chatComponent.vue";
import menuComponent from "../components/menuComponent.vue";
import userLogon from "../components/userLogon.vue";
import $ from 'jquery';

export default {
    name: "responsiveHeader",
    components: {
        notificationsComponent,
        chatComponent,
        menuComponent,
        userLogon
    },
    data() {
        return {
            responsiveChat: false,
            forceCloseChat: false
        }
    },  
    watch: {
        forceCloseChat: function () {
            if (this.forceCloseChat) {
                setTimeout(() => {
                    this.forceCloseChat = !this.forceCloseChat;
                }, 1)
            }
        }
    },
    methods: {
        toggleMenu: function () {
            let container = $(".menu");
            let wrapper = $(".menu-wrapper");

            if (container.is(":visible")) {
                container.hide();
                wrapper.hide();
            } else {
                container.show();
                wrapper.show();
            }
        },
        closeMenu: function () {
            let container = $(".menu");
            let wrapper = $(".menu-wrapper");

            container.hide();
            wrapper.hide();
        },
        toggleChat: function () {
            let container = $(".chat-container");
            let wrapper = $(".chat-wrapper");

            if (container.is(":visible")) {
                container.hide();
                wrapper.hide();
            } else {
                container.show();
                wrapper.show();
            }
        },
        closeChat: function () {
            if (this.responsiveChat) {
                let container = $(".chat-container");
                let wrapper = $(".chat-wrapper");

                if (container.is(":visible")) {
                    container.hide();
                    wrapper.hide();
                }
            }
        },
        returnActionInner: function () {
            let self = this;

            if ($(".chat-container").is(":visible") && !$(".chat-box").is(":visible")) {
                self.closeChat();
            }
            
            if ($(".chat-box").is(":visible")) {
                self.forceCloseChat = true;
            }
        },
        receptReturnAction: function () {
            let self = this;

            window.history.pushState(null, "", window.location.href);

            window.addEventListener('popstate', function() {
                self.returnActionInner();

                window.history.pushState(null, "", window.location.href);
            });

        }
    },
    mounted: function () {
        let self = this;

        if (window.innerWidth <= 1050) {
            self.responsiveChat = true;
            self.receptReturnAction();
        }

        $(window).on("resize", () => {
            if (window.innerWidth <= 1050) {
                self.responsiveChat = true;
                self.receptReturnAction();
            }
        })
    }
}
</script>
<style scoped>
header {
    background-image: linear-gradient(to top right, var(--pink), var(--pink-high));
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: var(--space-5);
}

.grouping {
    display: flex;
    align-items: center;
}

    .grouping span, .chat-icon {
        font-size: var(--fontsize-lg);
        color: var(--purple);
        margin: 0 var(--space-7);
        display: inline-block;
        cursor: pointer;
    }

.user-logon {
    margin-left: var(--space-6);
    display: none;
}

.chat-icon {
    display: none !important;
}

.chat-container, .menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 50%;
}

.menu {
    left: 50%;
    width: fit-content;
    white-space: nowrap;
}

.menu-wrapper {
    z-index: 99999 !important;
}

.chat-wrapper, .menu-wrapper {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 3;
}

.grouping {
    position: relative;
    z-index: 5;
}

.grouping:first-child {
    z-index: 999999 !important;
}

@media (max-width: 1050px) {
    .chat-icon {
        display: block !important;
    }

    .user-logon {
        display: block !important;
    }
}

@media (max-width: 768px) {
    .chat-container {
        position: fixed;
        left: 0;
        width: 100vw;
        top: 70px;
        height: 100%;
    }
}
</style>