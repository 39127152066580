<template>
    <div class="user-card" v-on:click="goToProfile()">
        <div class="user">
            <img :src="$root.user.profile_photo" class="avatar-p">
            <div class="user-informations">
                <h2>{{ $root.user.nickname }}</h2>
                <h3>{{ $root.user.nick_at_sign }}</h3>
            </div>
        </div>
        <div class="user-statistics" v-if="!reload">
            <div class="statistic">
                <h2>{{ $root.user.friends }}</h2>
                <h3>Amigos</h3>
            </div>
            <div class="statistic">
                <h2>{{ $root.user.posts }}</h2>
                <h3>Publicações</h3>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "userCard",
    data() {
        return {
            reload: false
        }
    },
    watch: {
        reload: function () {
            if (this.reload) {
                
                setTimeout(() => {
                    console.log(this.$root.user)
                    this.reload = false;
                }, 1)
            }
        }
    },
    methods: {
        goToProfile: function () {
            if (window.location.pathname.indexOf("/dashboard/users/" + this.$root.user.id) == -1) {
                this.$router.push("/dashboard/users/" + this.$root.user.id);
            }
        },
        wsReloadSystem: function () {
            let self = this;

            self.$root.socket.addEventListener("message", function (event) {
                let message = JSON.parse(event.data);

                if (message.type == "reload_system") {
                    self.reload = true;
                }
            });
        }
    },
    mounted: function () {
        this.wsReloadSystem();
    }
}
</script>
<style scoped>

.user-card {
    background: var(--pink-mid);
    border: 3px solid var(--purple);
    border-radius: var(--radius-sm);
    padding: var(--space-7);
    cursor: pointer;
}

.user {
    display: flex;
    align-items: center;
}

.user-statistics {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: var(--space-7);
    flex-wrap: wrap;
}

.statistic {
    margin: var(--space-3);
}
</style>